import { Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router";
import Proptypes from "prop-types";
import styled from "styled-components";
import Button from "./Button";
import Header from "./Header";
import { APP_LINKS } from "../utils/constants";

const StyledBtnTxt = styled.div`
  text-align: left;
  font: normal normal bold 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  text-transform: uppercase;
`;

const StyledHeader = styled.div`
  position: sticky;
  top: 32px;
  z-index: 999;
  width: 100%;
`;

function NestedPagesHeader({ icon, title, link }) {
  const navigate = useNavigate();
  return (
    <StyledHeader>
      <Row align="middle" justify="space-between" gutter={[20, 20]}>
        <Col span={{ xs: 24, xl: 10 }}>
          <Row>
            <Button
              type="outline"
              onClick={() => {
                navigate(APP_LINKS.DASHBOARD);
              }}
            >
              <StyledBtnTxt>&lt; BACK TO DASHBOARD</StyledBtnTxt>
            </Button>
          </Row>
        </Col>
        <Col span={{ xs: 24, xl: 14 }}>
          <Header
            icon={icon}
            title={title}
            link={link}
            style={{
              width: 741,
            }}
            addressMaxWidth={150}
          />
        </Col>
      </Row>
    </StyledHeader>
  );
}

NestedPagesHeader.propTypes = {
  icon: Proptypes.any,
  title: Proptypes.string,
  link: Proptypes.any,
};

export default NestedPagesHeader;
