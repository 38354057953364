import { Row } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Proptypes from "prop-types";
import { THEMES } from "../utils/constants";
import inconsistentColors from "../utils/helpers/inconsistentColors";
import { ReactComponent as Loader } from "../icons/loader.svg";

const StyledStakingTable = styled.div`
  padding-bottom: 50px;
`;
const StyledTableHead = styled.div`
  padding-top: 10px;
  padding-bottom: 12px;
  border-radius: 15px 15px 0px 0px;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => (theme.id === THEMES.DARK ? theme.fg1 : theme.fg3)};
  font: normal normal 300 16px/23px Circe;
`;

const StyledTableRow = styled.div`
  padding-top: 10px;
  padding-bottom: 12px;
  margin-top: 8px;
  background-color: ${({ theme }) => theme.bg3};
  color: ${({ theme }) => (theme.id === THEMES.DARK ? theme.fg1 : theme.fg3)};
  font: normal normal 300 16px/23px Circe;
`;

function EmptyRow({ children }) {
  return (
    <StyledTableRow style={{ height: 66 }}>
      <Row align="middle" justify="center" style={{ height: "100%" }}>
        {children}
      </Row>
    </StyledTableRow>
  );
}
EmptyRow.propTypes = {
  children: Proptypes.any,
};

function Table({ head, rows, widths, loading, emptyMsg }) {
  const contRef = useRef(null);
  const [fullWidth, setFullWidth] = useState(-1);
  useEffect(() => {
    function onResize() {
      setFullWidth(contRef.current?.offsetWidth);
    }
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  const getCellWidth = (i) => {
    const ratio = widths?.length > 0 ? widths[i] : 1 / head.length;
    if (fullWidth === -1) return `${ratio * 100}%`;
    return fullWidth * ratio;
  };
  const theme = useContext(ThemeContext);
  const seperatorColor = inconsistentColors(
    "rgba(84, 91, 122, 0.65)",
    "rgba(83, 97, 149, 0.65)"
  )({ theme });

  return (
    <StyledStakingTable ref={contRef}>
      <StyledTableHead>
        <Row>
          {(head || []).map((e, i) => (
            <Row
              key={i}
              justify="center"
              align="middle"
              style={{
                textAlign: "center",
                minHeight: "100%",
                borderLeft: i !== 0 ? `1px solid ${seperatorColor}` : null,
                width: getCellWidth(i),
              }}
            >
              {e}
            </Row>
          ))}
        </Row>
      </StyledTableHead>
      {loading ? (
        <EmptyRow>
          <Loader height="30px" alt="" />
        </EmptyRow>
      ) : null}

      {!loading && rows?.length === 0 ? (
        <EmptyRow>{emptyMsg || "No data"}</EmptyRow>
      ) : null}

      {!loading
        ? (rows || []).map((r, i) => (
            <StyledTableRow key={i}>
              <Row>
                {r.map((e, j) => (
                  <Row
                    key={j}
                    justify="center"
                    align="middle"
                    style={{
                      textAlign: "center",
                      minHeight: "100%",
                      borderLeft:
                        j !== 0 ? `1px solid ${seperatorColor}` : null,
                      width: getCellWidth(j),
                    }}
                  >
                    {e}
                  </Row>
                ))}
              </Row>
            </StyledTableRow>
          ))
        : null}
    </StyledStakingTable>
  );
}

Table.propTypes = {
  head: Proptypes.array.isRequired,
  rows: Proptypes.array.isRequired,
  widths: Proptypes.array,
  loading: Proptypes.bool,
  emptyMsg: Proptypes.any,
};

export default Table;
