import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";

import HeaderLinkItem from "../../lib/HeaderLinkItem";
import Header from "../../lib/Header";
import { APP_LINKS } from "../../utils/constants";

const StyledHeader = styled(Header)`
  position: sticky;
  top: 32px;
  z-index: 999;
`;

export default function DashboardHeader(props) {
  return (
    <StyledHeader {...props}>
      <Row gutter={[16, 10]}>
        <Col>
          <HeaderLinkItem to={APP_LINKS.PUBLICSALE}>Public sale</HeaderLinkItem>
        </Col>
        <Col>
          <HeaderLinkItem to={APP_LINKS.DASHBOARD}>Dashboard</HeaderLinkItem>
        </Col>
        <Col>
          <HeaderLinkItem to={APP_LINKS.SWAP}>Swap</HeaderLinkItem>
        </Col>
        <Col>
          <HeaderLinkItem to={APP_LINKS.FARMING}>Farming</HeaderLinkItem>
        </Col>
        <Col>
          <HeaderLinkItem to={APP_LINKS.STAKING}>Staking</HeaderLinkItem>
        </Col>
      </Row>
    </StyledHeader>
  );
}
