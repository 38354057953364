import React from "react";
import styled, { css } from "styled-components";
import Proptypes from "prop-types";
import StyledBasicButton from "./BasicButton";
import { ReactComponent as Loader } from "../icons/loader.svg";

const StyledMyTvButton = styled(StyledBasicButton)`
  background: transparent
    linear-gradient(
      90deg,
      rgba(86, 193, 240, ${({ $loading }) => ($loading ? 0.9 : 1)}) 0%,
      rgba(71, 51, 192, ${({ $loading }) => ($loading ? 0.9 : 1)}) 100%
    )
    0% 0% no-repeat padding-box;
  border-radius: 60px;
  opacity: 1;
  height: 55px;
  padding: 5px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font: normal normal bold 16px/23px Circe;
  font-family: Circe;
  letter-spacing: 0px;
  color: #ffffff;
  ${({ $disabled }) =>
    $disabled
      ? css`
          opacity: 0.3;
          cursor: not-allowed;
          &:hover {
            opacity: 0.3;
          }
          &:active {
            opacity: 0.3;
            box-shadow: none;
          }
        `
      : ""}
`;

const iconStyle = {
  marginLeft: 20,
};

export default function MyTvButton({
  children,
  iconUrl,
  disabled,
  style,
  onClick,
  className,
  loading,
}) {
  return (
    <StyledMyTvButton
      style={style}
      onClick={(evt) => {
        if (!loading && onClick && !disabled) onClick(evt);
      }}
      className={className}
      $loading={loading}
      $disabled={disabled}
    >
      {children}
      {iconUrl && !loading && <img style={iconStyle} src={iconUrl} alt="" />}
      {loading && <Loader style={iconStyle} alt="" fill="#ffffff" />}
    </StyledMyTvButton>
  );
}

MyTvButton.propTypes = {
  children: Proptypes.any,
  iconUrl: Proptypes.string,
  disabled: Proptypes.bool,
  style: Proptypes.any,
  onClick: Proptypes.func,
  className: Proptypes.string,
  loading: Proptypes.bool,
};
