import React from "react";
import styled from "styled-components";
import { Col, Row } from "antd";
import Proptypes from "prop-types";
import DropDown from "./DropDown";
import Input from "./Input";

const StyledExchangeBlock = styled.div`
  background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 32px;
  height: 119px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.fg1};
  width: 150px;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledExchangeIcon = styled.img`
  width: 55px;
  height: auto;
`;

const StyledExchangeName = styled.div`
  font: normal normal bold 24px/35px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledInput = styled(Input)`
  input {
    padding-right: 10px !important;
    text-align: right;
  }
`;

function ExchangeBlock({ note, value, onChange, options }) {
  const input = value?.input || "";
  const currency = value?.currency || null;
  const setCurrency = (v) => onChange({ ...value, currency: v });
  const setInput = (v) => onChange({ ...value, input: v });
  const handleCurrencyChoice = (v) => {
    setCurrency(v);
  };

  return (
    <StyledExchangeBlock>
      <Row gutter={15} style={{ flex: 1 }}>
        <Col>
          <StyledExchangeIcon src={currency?.url} alt="" />
        </Col>
        <Col>
          <StyledTitle>{note}</StyledTitle>

          <Row>
            <DropDown onChange={handleCurrencyChoice} options={options || []}>
              <StyledExchangeName>{currency?.label}</StyledExchangeName>
            </DropDown>
          </Row>
        </Col>
      </Row>
      <StyledInput
        style={{ flex: 1 }}
        placeholder="0.0"
        suffix="Max"
        isNumber
        value={input}
        borderRadius="10px"
        onChange={(evt) => setInput(evt.target.value)}
      />
    </StyledExchangeBlock>
  );
}

ExchangeBlock.propTypes = {
  note: Proptypes.string.isRequired,
  value: Proptypes.any.isRequired,
  onChange: Proptypes.func.isRequired,
  options: Proptypes.array.isRequired,
};

export default ExchangeBlock;
