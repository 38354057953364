import React, { useContext, useState } from "react";
import Proptypes from "prop-types";

const initialState = {
  providerName: "",
  provider: null,
  web3: null,
};

export const Web3StateContext = React.createContext(initialState);
export const Web3DispatchContext = React.createContext(() => {});

export const useWeb3StateContext = () => useContext(Web3StateContext);
export const useWeb3DispatchContext = () => useContext(Web3DispatchContext);

function Web3ContextProvier({ children }) {
  const [state, dispatch] = useState(initialState);
  return (
    <Web3DispatchContext.Provider value={dispatch}>
      <Web3StateContext.Provider value={state}>
        {children}
      </Web3StateContext.Provider>
    </Web3DispatchContext.Provider>
  );
}
Web3ContextProvier.propTypes = { children: Proptypes.any };

export default Web3ContextProvier;
