import React from "react";
import { Col, Row } from "antd";
import styled from "styled-components";
import ContactLink from "../../lib/ContactLink";
import DashboardCard from "../../lib/DashboardCard";

import telegramLogo from "../../icons/telegram.png";
import twitterLogo from "../../icons/twitter.png";
import discordLogo from "../../icons/discord.png";
import logo from "../../icons/logo.svg";

const telegram = {
  text: "Telegram",
  icon: telegramLogo,
  url: process.env.REACT_APP_TELEGRAM_URL,
};
const twitter = {
  text: "Twitter",
  icon: twitterLogo,
  url: process.env.REACT_APP_TWITTER_URL,
};
const discord = {
  text: "Discord",
  icon: discordLogo,
  url: process.env.REACT_APP_DISCORD_URL,
};
const website = {
  text: "Website",
  icon: logo,
  url: process.env.REACT_APP_WEBSITE_URL,
};

const StyledTitle = styled.span`
  margin-bottom: 12px;
`;

function ContactCard() {
  return (
    <DashboardCard
      style={{ padding: "22px 33px 24px 33px", height: 184 }}
      title={<StyledTitle>Contact</StyledTitle>}
    >
      <Row justify="space-between">
        <Col>
          <ContactLink {...telegram} />
        </Col>

        <Col>
          <ContactLink {...discord} />
        </Col>
      </Row>
      <br />
      <Row justify="space-between">
        <Col>
          <ContactLink {...twitter} />
        </Col>
        <Col>
          <ContactLink {...website} />
        </Col>
      </Row>
    </DashboardCard>
  );
}

export default ContactCard;
