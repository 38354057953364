import styled, { createGlobalStyle, css } from "styled-components";
import Proptypes from "prop-types";
import { Input as AntdInput } from "antd";
import inconsistentColors from "../utils/helpers/inconsistentColors";

const StyledInput = styled(AntdInput)`
  &,
  &:focus,
  &:hover {
    ${({ $borderWidth, $borderColor }) =>
      $borderWidth > 0
        ? css`
            border: ${$borderWidth}px solid ${$borderColor};
          `
        : ``}

    border-width: ${({ $borderWidth }) => $borderWidth}px !important;
    border-right-width: ${({ $borderWidth }) => $borderWidth}px !important;
  }
  &:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: ${({ $borderWidth }) => $borderWidth}px !important;
  }
  ${({ theme, $backgroundColor }) =>
    $backgroundColor
      ? css`
          background: ${$backgroundColor} 0% 0% no-repeat padding-box !important;
        `
      : css`
          background: ${theme.bg1} 0% 0% no-repeat padding-box !important;
        `}
  width: 100%;
  height: 60px;
  border-radius: ${({ $borderRadius }) => $borderRadius};
  padding-left: 20px;
  ::placeholder {
    color: ${({ theme }) => theme.fg2};
  }
  .ant-input {
    ${({ theme, $backgroundColor }) =>
      $backgroundColor
        ? css`
            background: ${$backgroundColor} 0% 0% no-repeat padding-box !important;
          `
        : css`
            background: ${theme.bg1} 0% 0% no-repeat padding-box !important;
          `} font-family: Circe;
    font-weight: normal;
    color: ${({ theme }) => theme.fg1};
    font-size: 16px;
  }
  padding-right: 0;
  .ant-input-suffix {
    color: ${inconsistentColors("rgba(20, 29, 64, 0.5)", "fg2")};
    padding: 0 24px;
    border-left: 1px solid ${inconsistentColors("rgba(20, 29, 64, 0.5)", "fg2")};
    font: normal normal 300 16px Circe;
    margin: 5px 0;
  }
  .ant-input {
    &:disabled {
      background: ${({ theme }) => theme.bg6} !important;
    }
  }
  &.ant-input-affix-wrapper-disabled {
    background: ${({ theme }) => theme.bg6} !important;
  }
`;

const OnErrorStyle = createGlobalStyle`
  .ant-form-item-has-error
    :not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper {
    background-color: ${inconsistentColors("", "#361b39")} !important;
    .ant-input {
      background-color: ${inconsistentColors("", "#361b39")} !important;
    }
  }

  .ant-form-item-has-error .ant-checkbox-inner {
    border-color: ${inconsistentColors("#ff4d4f", "#361b39")};
  }
`;

function Input({
  onChange,
  isNumber,
  className,
  style,
  borderColor,
  borderWidth = 0,
  borderRadius = "20px",
  backgroundColor,
  ...props
}) {
  return (
    <>
      <OnErrorStyle />
      <StyledInput
        {...props}
        autoComplete="off"
        $borderWidth={borderWidth}
        $borderColor={borderColor}
        className={className}
        style={style}
        $borderRadius={borderRadius}
        $backgroundColor={backgroundColor}
        onChange={(evt) => {
          if (!onChange) return;
          if (isNumber) {
            const v = evt.target.value;

            const [p0, p1] = v.split(".");
            const pn = v.split(".").length;

            const numberRegex = /^\d*$/;

            if (
              v === "" ||
              v === "." ||
              (pn === 1 && numberRegex.test(p0)) ||
              (pn === 2 && numberRegex.test(p0) && numberRegex.test(p1))
            ) {
              onChange(evt);
            } else {
              // eslint-disable-next-line no-param-reassign
              evt.target.value = props.value || "";
              onChange(evt);
            }
          } else {
            onChange(evt);
          }
        }}
      />
    </>
  );
}

Input.propTypes = {
  onChange: Proptypes.func,
  value: Proptypes.any,
  isNumber: Proptypes.bool,
  className: Proptypes.string,
  style: Proptypes.any,
  borderColor: Proptypes.string,
  borderWidth: Proptypes.number,
  borderRadius: Proptypes.any,
  backgroundColor: Proptypes.string,
};

export default Input;
