import { Row } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import { Link, useParams, useNavigate } from "react-router-dom";
import Page from "../lib/Page";
import dashboard from "../icons/dashboard.svg";
import NestedPagesHeader from "../lib/NestedPagesHeader";
import { APP_LINKS, THEMES } from "../utils/constants";
import FarmingsListTable from "../components/staking-details/FarmingListTable";
import StakingsListTable from "../components/staking-details/StakingListTable";

const StyledExtra = styled(Link)`
  text-align: right;
  text-decoration: underline;
  font: normal normal bold 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  &:hover {
    color: ${({ theme }) => theme.fg1};
  }
`;

const StyledTitle = styled.div`
  font: normal normal bold 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  margin-top: 42px;
  margin-bottom: 18px;
`;

const MainContainer = styled.div`
  width: calc(100% - 32px);
  margin-left: 16px;
  margin-right: 16px;
`;

const StyledPage = styled(Page)`
  background-image: url("/images/grid1.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: right 70px top 10px;
`;

const StyledTabIcon = styled.div`
  position: relative;
  text-align: center;
  transition: all 0.1s ease-out;
  font: ${({ $active }) =>
    $active
      ? "normal normal bold 18px/26px Circe"
      : "normal normal 300 18px/26px Circe"};
  letter-spacing: 0px;
  line-height: 26px;
  color: ${({ theme, $active }) => {
    if (theme.id === THEMES.DARK) return $active ? "#F6F9FF" : "#536195";

    return theme.fg1;
  }};
  padding: 8px 12px;
  margin-right: 1px;
  cursor: pointer;
  user-select: none;
  width: 104px;
`;

const calcColor = ({ $active, theme }) => {
  if (theme.id === THEMES.DARK) return $active ? "#BADEFF" : "#536195";
  return $active ? "#08A0F7" : "#888FA6";
};

const StyledTabIconIdicator = styled.div`
  position: absolute;
  transition: all 0.1s ease-out;
  bottom: -2px;
  left: 0;
  width: 100%;
  height: 0;
  border-radius: 4px;
  background-color: ${calcColor};
  border: 1.5px solid ${calcColor};
`;

const StyledTabsList = styled(Row)`
  border-bottom: 1px solid
    ${({ theme }) =>
    theme.id === THEMES.DARK ? "#536195" : "rgba(84, 91, 122, 0.65)"};
`;

function TabsList({ active, onChange, items, style }) {
  const navigate = useNavigate();
  return (
    <StyledTabsList justify="space-between" align="middle" style={style}>
      <Row>
        {(items || []).map((e) => {
          const isActive = active === e.id;

          return (
            <StyledTabIcon
              key={e.id}
              $active={isActive}
              onClick={() => {
                onChange(e.id);
                navigate(`${APP_LINKS.STAKING_DETAILS}/${e.id}`);
              }}
            >
              {e.label}
              <StyledTabIconIdicator $active={isActive} />
            </StyledTabIcon>
          );
        })}
      </Row>
      <StyledExtra style={{ display: active === "farming" ? "initial" : "none" }} to={APP_LINKS.FARMING}>Want to farm ?</StyledExtra>
      <StyledExtra style={{ display: active === "staking" ? "initial" : "none" }} to={APP_LINKS.STAKING}>Want to stake ?</StyledExtra>
    </StyledTabsList>
  );
}

TabsList.propTypes = {
  active: Proptypes.any,
  onChange: Proptypes.func,
  items: Proptypes.array,
  style: Proptypes.any,
};

function StakingDetail() {
  const [activeTabId, setActiveTabId] = useState("farming");
  const { id } = useParams();
  useEffect(() => {
    if (id === "farming" || id === "staking") {
      setActiveTabId(id);
    }
  }, [id]);

  return (
    <StyledPage
      header={
        <NestedPagesHeader
          title="Your dashboard"
          icon={dashboard}
          link={APP_LINKS.DASHBOARD}
        />
      }
    >
      <MainContainer>
        <StyledTitle>
          <img src={dashboard} alt="" style={{ marginRight: 16 }} />
          Monitoring
        </StyledTitle>
        <TabsList
          active={activeTabId}
          onChange={setActiveTabId}
          items={[
            { id: "farming", label: "Farming" },
            { id: "staking", label: "Staking" },
          ]}
          style={{ marginBottom: 60 }}
        />
        <FarmingsListTable active={activeTabId === "farming"} />
        <StakingsListTable active={activeTabId === "staking"} />
      </MainContainer>
    </StyledPage>
  );
}

export default StakingDetail;
