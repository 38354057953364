import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledContactLink = styled.a`
  max-width: 140px;
  font-family: Circe;
  display: flex;
  align-items: center;
  width: fit-content;
`;

const StyledIcon = styled.img`
  width: 29px;
  height: 29px;
  margin-right: 25px;
  display: inline-block;
`;

const StyledText = styled.div`
  color: ${({ theme }) => theme.fg1};
  font: normal normal 300 16px/33px Circe;
  font-weight: light;
  display: inline-block;
`;

export default function ContactLink({ text, icon, url }) {
  return (
    <StyledContactLink href={url} target="_blank" rel="noreferrer">
      <StyledIcon src={icon} alt="icon" />
      <StyledText>{text}</StyledText>
    </StyledContactLink>
  );
}
ContactLink.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  url: PropTypes.string,
};
