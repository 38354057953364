import { ethers } from 'ethers';

import MyTvGovernanceABI from '../utils/contracts/MyTvGovernance_ABI.json';
import MyTvStakingABI from '../utils/contracts/MyTvStaking_ABI.json';
import MyTvFarmingABI from '../utils/contracts/MyTvFarming_ABI.json';
import MyTvPublicSaleABI from '../utils/contracts/MyTvPublicSale_ABI.json';
import ERC20_ABI from '../utils/contracts/ERC20_ABI.json';
import MyTvLockABI from "../utils/contracts/MyTvLock_1.0_ABI.json";
import MyTvLockPrivateABI from "../utils/contracts/MyTvLockPrivate_1.0.ABI.json";

import {
  FARMING_CONTRACT_ADDRESS,
  GOVERNANCE_CONTRACT_ADDRESS,
  POOL_FARMING_CONTRACT_ADDRESS,
  PUBLIC_SALE_CONTRACT_ADDRESS,
  STAKING_CONTRACT_ADDRESS,
  JSON_RPC_PROVIDER_URI,
  MY_TV_PANCAKESWAP_ADDRESS,
  VESTING_LOCK_ADDRESS,
  VESTING_PRIVATE_LOCK_ADDRESS,
  PANCAKE_POOL_ADDRESS,
  INITIAL_SUPPLY,
} from "../utils/constants";

export const MYTV = 4;
export const DAY_IN_S = 24 * 60 * 60;

let MyTvContractsInstance = null;

export class MyTvContracts {
  constructor() {

    this.poolTokenPrice = null;

    if (MyTvContractsInstance) {
      return MyTvContractsInstance;
    }
    MyTvContractsInstance = this;

    const provider = new ethers.providers.JsonRpcProvider(
      JSON_RPC_PROVIDER_URI,
    );

    this.provider = provider;

    this.governance = new ethers.Contract(
      GOVERNANCE_CONTRACT_ADDRESS,
      MyTvGovernanceABI,
      provider,
    );

    this.staking = new ethers.Contract(
      STAKING_CONTRACT_ADDRESS,
      MyTvStakingABI,
      provider,
    );

    this.farming = new ethers.Contract(
      FARMING_CONTRACT_ADDRESS,
      MyTvFarmingABI,
      provider,
    );

    this.publicSale = new ethers.Contract(
      PUBLIC_SALE_CONTRACT_ADDRESS,
      MyTvPublicSaleABI,
      provider,
    );

    this.poolFarming = new ethers.Contract(
      POOL_FARMING_CONTRACT_ADDRESS,
      ERC20_ABI,
      provider,
    );

    this.myTvLock = new ethers.Contract(
      VESTING_LOCK_ADDRESS,
      MyTvLockABI,
      provider,
    );

    this.myTvPrivateLock = new ethers.Contract(
      VESTING_PRIVATE_LOCK_ADDRESS,
      MyTvLockPrivateABI,
      provider,
    );

    this.wBnb = new ethers.Contract(
      "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
      ERC20_ABI,
      provider,
    );

  }

  /**
   * @returns {Promise<string>}
   */
  async totalSupply() {
    return this.governance.totalSupply();
  }

  /**
   * @returns {Promise<string>}
   */
  async totalStake() {
    const staked = await this.staking.totalStake();
    return ethers.utils.formatUnits(staked, MYTV);
  }

  /**
   * @returns {Promise<string>}
   */
  async totalPancakeSwapTokens() {
    return this.governance.balanceOf(
      MY_TV_PANCAKESWAP_ADDRESS,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalFarmingLocked() {
    return this.governance.balanceOf(
      FARMING_CONTRACT_ADDRESS,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalMyTvFarmingLocked() {
    return this.governance.balanceOf(
      POOL_FARMING_CONTRACT_ADDRESS,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalBnbFarmingLocked() {
    return this.wBnb.balanceOf(POOL_FARMING_CONTRACT_ADDRESS);
  }

  /**
   * @returns {Promise<string>}
   */
  async totalStakedLocked() {
    return this.governance.balanceOf(
      STAKING_CONTRACT_ADDRESS,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalVestingLocked() {
    return this.governance.balanceOf(
      VESTING_LOCK_ADDRESS,
    );
  }

  /**
   * @returns {Promise<string>}
   */
  async totalPrivateVestingLocked() {
    return this.governance.balanceOf(
      VESTING_PRIVATE_LOCK_ADDRESS,
    );
  }

  async myVestingLock(wallet) {
    const addressToTokenLock = await this.myTvLock.addressToTokenLock(wallet);
    return addressToTokenLock.amount;
  }

  async myPrivateVestingLock(wallet) {
    const addressToTokenLock = await this.myTvPrivateLock.addressToTokenLock(wallet);
    return addressToTokenLock.amount;
  }

  /**
   * @returns {Promise<string>}
   */
  async balanceOf(wallet) {
    try {
      const tokens = await this.governance.balanceOf(wallet);
      return ethers.utils.formatUnits(tokens, MYTV);
    } catch (error) {
      console.error(error);
      return '0';
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async getTotalMultiSigBalance() {
    try {
      const ms1 = await this.governance.balanceOf("0x20FbddEF71084541235AD54DCcFfB8e0e92Fa676");
      const ms2 = await this.governance.balanceOf("0x6a78FEffA5126C031Bb6be95b7f5f60781e540eC");
      const ms3 = await this.governance.balanceOf("0xCFc52AAC6ce4919615A5d27751e8A0E0F44be167");
      const msX = await Promise.all([ms1, ms2, ms3]);
      const total = msX.reduce((prev, current) => {
        return prev.add(current);
      });

      return total;
    } catch (error) {
      console.error(error);
      return '0';
    }
  }

  async totalBurn() {
    return this.governance.balanceOf(ethers.constants.AddressZero);
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserPendingFarmingReawards(wallet) {
    try {
      const poolLength = await this.farming.poolLength();
      const rewardsP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        rewardsP.push(this.farming.pendingMyTv(i, wallet));
      }
      const rewards = await Promise.all(rewardsP);
      return rewards.reduce(
        (acc, reward) => acc + ethers.utils.formatUnits(reward, MYTV),
        0,
      );
    } catch (error) {
      return '0';
    }
  }

  async getPoolTokenPrice(tokenPrice) {
    if (this.poolTokenPrice) return this.poolTokenPrice;

    const response = await fetch("https://api.pancakeswap.info/api/v2/tokens/0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c");
    const data = await response.json();

    const balancePancakePoolBnb = await this.wBnb.balanceOf(PANCAKE_POOL_ADDRESS);
    const balancePancakePoolMtv = await this.governance.balanceOf(PANCAKE_POOL_ADDRESS);

    const poolPrice = (ethers.utils.formatUnits(balancePancakePoolMtv, 4) * tokenPrice) + (data.data.price * ethers.utils.formatUnits(balancePancakePoolBnb, 18));
    this.poolTokenPrice = poolPrice / ethers.utils.formatUnits(await this.poolFarming.totalSupply(), 18);
    return this.poolTokenPrice;
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserFarming(wallet, tokenPrice) {
    try {
      const poolLength = await this.farming.poolLength();
      const farmedsP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        farmedsP.push(this.farming.userInfo(i, wallet));
      }

      const farmeds = await Promise.all(farmedsP);
      const farmedValue = farmeds.reduce((acc, farmed) => {
        return (acc + Number(ethers.utils.formatUnits(farmed.amount, 18)));
      }, 0);

      return farmedValue * await this.getPoolTokenPrice(tokenPrice);
    } catch (error) {
      return '0';
    }
  }

  /**
   * @returns {Promise<string>}
   */
  async totalUserStakingReawards(wallet) {
    try {
      const poolLength = await this.staking.getLengthOfUserStaking(wallet);

      const rewardsP = Array(poolLength.toNumber()).fill().map((v, i) => {
        return this.staking.getUserRewardLock(wallet, i);
      });

      const rewards = await Promise.all(rewardsP);
      const total = rewards.reduce(
        (acc, reward) => acc.add(reward),
        ethers.BigNumber.from(0),
      );
      return ethers.utils.formatUnits(total, MYTV);
    } catch (error) {
      console.error('4', error);
      return '0';
    }
  }

  async totalUserStakings(wallet) {
    try {
      const poolLength = await this.staking.getLengthOfUserStaking(wallet);
      const stakedP = [];
      for (let i = 0; i < poolLength.toNumber(); i++) {
        stakedP.push(this.staking.staked(wallet, i));
      }
      const stakeds = await Promise.all(stakedP);
      return stakeds.reduce(
        (acc, stake) =>
          acc + Number(ethers.utils.formatUnits(stake.amount, MYTV)),
        0,
      );
    } catch (error) {
      return '0';
    }
  }

  async getStakingPacks() {
    try {
      const lengthOfPacks = await this.staking.getLengthOfStakingPackOptions();
      const packsP = [];
      for (let i = 0; i < lengthOfPacks.toNumber(); i++) {
        packsP.push(this.staking.stakingOptions(i));
      }

      const packs = await Promise.all(packsP);
      let filtered = [];
      filtered = packs.filter(
        (p) => p.rate.toNumber() > 0 && !p.onlyAdmin,
      );

      return filtered.map((p) => ({
        lock: Math.round(p.period.toNumber() / DAY_IN_S),
        apr: p.rate.toNumber(),
      }));
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  /**
   * @type {Promise<ethers.BigNumber>}
   */
  async getTokenPerBlock() {
    return this.farming.mytvPerBlock();
  }

  /**
   * @type {Promise<ethers.BigNumber>}
   */
  async getFarmingBalance() {
    return this.poolFarming.balanceOf(FARMING_CONTRACT_ADDRESS);
  }

  /**
   * @type {Promise<ethers.BigNumber>}
   */
  async getFarmingBalanceUser(userAddress) {
    return this.poolFarming.balanceOf(userAddress);
  }

  async circulatingSupply() {
    
    const all = await Promise.all([
      this.governance.balanceOf("0xc1b973515113034fb2b2d1a4891734005222c774"),
      this.governance.balanceOf("0xb3a97fb4bc1d1a1c873862b983834d6902afce5f"),
      this.governance.balanceOf("0x20fbddef71084541235ad54dccffb8e0e92fa676"),
      this.governance.balanceOf("0xcfc52aac6ce4919615a5d27751e8a0e0f44be167"),
      this.governance.balanceOf("0x6a78feffa5126c031bb6be95b7f5f60781e540ec"),
      this.governance.balanceOf("0x5668c6220a0335eba21dbf5aa76f01cf02c42ac8"),
      this.governance.balanceOf("0x12523a6a2400081fea7ae6c39448832f16325b9c"),
      this.governance.balanceOf("0xf22013b21db3c737e79d44f7337b2982dd20ceb8"),
      this.totalBurn()
    ]);

    const supply = {
      initialSupply: ethers.utils.parseUnits(INITIAL_SUPPLY, 4),
      staking_1: all[0],
      farming_2: all[1],
      team_3: all[2],
      marketing_4: all[3],
      advisor_5: all[4],
      vesting_pub_6: all[5],
      vesting_priv_7: all[6],
      marketMakerGsr_8: all[7],
      burn: all[8],
    }

    const locked = supply.staking_1
      .add(supply.farming_2)
      .add(supply.team_3)
      .add(supply.marketing_4)
      .add(supply.advisor_5)
      .add(supply.vesting_pub_6)
      .add(supply.vesting_priv_7)
      .add(supply.marketMakerGsr_8)
      .add(supply.burn);

    const circulatingSupply = supply.initialSupply.sub(locked);

    return circulatingSupply;
  }
}