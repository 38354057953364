import { Row } from "antd";
import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";

const StyledContractInfoItem = styled(Row)`
  width: 100%;
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: #121b3e;
  display: grid;
  grid-template-columns: 33% 34% 33%;
  justify-content: space-between;
  min-height: 52px;
  align-items: center;
  justify-items: center;
`;

const StyledContractInfoItemTitle = styled.div`
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  justify-self: start;
`;
const StyledContractInfoItemInfo = styled.div`
  text-align: right;
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  justify-self: end;
`;

const StyledSeparator = styled.div`
  width: 80px;
  border-bottom: 1px solid ${({ theme }) => theme.fg1};
  opacity: 0.65;
`;

function ContractInfoItem({ title, info, style }) {
  return (
    <StyledContractInfoItem style={style}>
      <StyledContractInfoItemTitle>{title}</StyledContractInfoItemTitle>
      <StyledSeparator />
      <StyledContractInfoItemInfo>{info}</StyledContractInfoItemInfo>
    </StyledContractInfoItem>
  );
}

ContractInfoItem.propTypes = {
  title: Proptypes.any,
  info: Proptypes.any,
  style: Proptypes.any,
};

export default ContractInfoItem;
