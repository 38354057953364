import Portis from "@portis/web3";
import * as constants from "../constants";

export default async function preparePortis() {

  const bscNode = {
    nodeUrl: constants.PORTIS_NODE_URL,
    chainId: constants.PORTIS_CHAINID,
  };

  const portis = await new Portis(process.env.REACT_APP_PORTIS_DAPP, bscNode);

  window.portis = portis;

  return portis;
}
