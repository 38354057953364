export const THEMES = { DARK: "dark", LIGHT: "light" };

export const APP_LINKS = {
  FAQ: "/faq",
  FARMING: "/farming",
  SWAP: "/swap",
  STAKING: "/staking",
  STAKING_DETAILS: "/staking-detail",
  DASHBOARD: "/dashboard",
  PUBLICSALE: "/public-sale",
};

export const PROVIDER_NAMES = {
  METAMASK: "metamask",
  TORUS: "torus",
  WALLETCONNECT: "walletconnect",
  PORTIS: "portis",
};

export const PORTIS_NODE_URL = process.env.REACT_APP_PORTIS_NODE_URL;
export const PORTIS_CHAINID = process.env.REACT_APP_PORTIS_CHAINID;
export const WALLETCONNECT_RPC_URL = process.env.REACT_APP_WALLETCONNECT_RPC_URL;

export const STAKING_CONTRACT_ADDRESS = process.env.REACT_APP_STAKING_CONTRACT_ADDRESS;
export const GOVERNANCE_CONTRACT_ADDRESS = process.env.REACT_APP_GOVERNANCE_CONTRACT_ADDRESS;
export const POOL_FARMING_CONTRACT_ADDRESS = process.env.REACT_APP_POOL_FARMING_CONTRACT_ADDRESS;
export const FARMING_CONTRACT_ADDRESS = process.env.REACT_APP_FARMING_CONTRACT_ADDRESS;
export const PUBLIC_SALE_CONTRACT_ADDRESS = process.env.REACT_APP_PUBLIC_SALE_CONTRACT_ADDRESS;
export const PUBLIC_SALE_RESERVOIR_ADDRESS = process.env.REACT_APP_PUBLIC_SALE_RESERVOIR_ADDRESS;
export const POOL_FARMING_BLOCKS_PER_YEAR = process.env.REACT_APP_POOL_FARMING_BLOCKS_PER_YEAR;
export const PANCAKE_POOL_ADDRESS = process.env.REACT_APP_PANCAKE_POOL_ADDRESS;
export const VESTING_LOCK_ADDRESS = process.env.REACT_APP_VESTING_LOCK_ADDRESS;
export const VESTING_PRIVATE_LOCK_ADDRESS = process.env.REACT_APP_VESTING_PRIVATE_LOCK_ADDRESS;

export const JSON_RPC_PROVIDER_URI = process.env.REACT_APP_JSON_RPC_PROVIDER_URI;
export const MY_TV_PANCAKESWAP_ADDRESS = process.env.REACT_APP_MY_TV_PANCAKESWAP_ADDRESS;
export const PANCAKESWAP_API = process.env.REACT_APP_PANCAKESWAP_API;
export const INITIAL_SUPPLY = process.env.REACT_APP_INITIAL_SUPPLY;

export const DATE_FORMAT = "DD-MM-YYYY HH:mm:ss";

export const SIMPLE_DATE_FORMAT = "YYYY-MM-DD";

export const MAX_ALLOWANCE = 999999999999999;

//*
export const PUBLIC_SALE_START_DATE = new Date("2022/02/08 16:00:00 GMT+0100");
export const PUBLIC_SALE_PHASE_1_START_DATE = new Date("2022/02/08 16:00:00 GMT+0100");
export const PUBLIC_SALE_PHASE_2_START_DATE = new Date("2022/02/09 16:00:00 GMT+0100");
export const PUBLIC_SALE_END_DATE = new Date("2022/02/10 15:00:00 GMT+0100");
//*/

/*
export const PUBLIC_SALE_START_DATE = new Date("2022/02/02 14:00:00 GMT+0100");
export const PUBLIC_SALE_PHASE_1_START_DATE = new Date("2022/02/02 14:01:00 GMT+0100");
export const PUBLIC_SALE_PHASE_2_START_DATE = new Date("2022/02/02 14:00:00 GMT+0100");
export const PUBLIC_SALE_END_DATE = new Date("2022/03/02 16:00:00 GMT+0100");
//*/