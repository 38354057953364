import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  font-family: circe;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: 100%;
`;

const StyledCardTitle = styled.div`
  color: ${({ theme }) => theme.fg1};
  padding-right: 15px;
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  padding-right: 32px;
  margin-bottom: 4px;
`;

const StyledCardContent = styled.div`
  width: 100%;
  min-height: 81px;
  padding: 5px 32px 5px 0;
  font-size: 18px;
  background-color: ${({ theme }) => theme.bg5};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  color: ${({ theme }) => theme.icon2};
`;

const StyledCardItem = styled.div`
  font-weight: normal;
  color: ${({ theme }) => theme.fg1};
`;

export default function DashboardKPICard({
  title,
  firstAmount,
  secondAmount,
  disabled,
  unit1 = "MYTV",
}) {
  return (
    <StyledCard disabled={disabled}>
      <StyledCardTitle>{title}</StyledCardTitle>
      <StyledCardContent>
        <StyledCardItem>
          {!disabled ? firstAmount : ""} {unit1}
        </StyledCardItem>

        {secondAmount && (
          <StyledCardItem>
            ~ {!disabled ? secondAmount : "..."} USD
          </StyledCardItem>
        )}
      </StyledCardContent>
    </StyledCard>
  );
}

DashboardKPICard.propTypes = {
  title: Proptypes.any,
  firstAmount: Proptypes.any,
  secondAmount: Proptypes.any,
  disabled: Proptypes.bool,
  unit1: Proptypes.any,
};
