import Proptypes from "prop-types";
import styled from "styled-components";

const StyledProgressBarHeader = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.fg1};
  font: normal normal bold 14px/20px Circe;
`;

const StyledProgressBarWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.border1};
  border-radius: 10px;
  height: 18px;
  position: relative;
`;

const StyledProgressBar = styled.div`
  width: ${({ dynamicWidth }) => dynamicWidth}%;
  position: relative;
  background-color: ${({ dynamicWidth }) =>
    dynamicWidth === 0 ? "transparent" : "#2e86fb"};
  border-radius: 10px;
  height: 18px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  font: normal normal bold 12px/17px Circe;
  color: #ffffff;
  padding-right: 10px;
  transition: all 0.5s linear;
`;

const ValueDisplayer = styled.div`
  position: absolute;
  top: 1px;
  left: ${({ dynamicWidth }) => (dynamicWidth < 8 ? 1 : dynamicWidth - 8)}%;
  transition: all 0.5s linear;
`;

export default function ProgressBar({ percent, maxTxt, minTxt }) {
  return (
    <>
      <StyledProgressBarHeader>
        <div>{minTxt}</div>
        <div>{maxTxt}</div>
      </StyledProgressBarHeader>
      <StyledProgressBarWrapper>
        <StyledProgressBar dynamicWidth={percent}>
          <ValueDisplayer dynamicWidth={percent}>
            {percent < 0.01 ? "0" : percent.toFixed(2)}%
          </ValueDisplayer>
        </StyledProgressBar>
      </StyledProgressBarWrapper>
    </>
  );
}

ProgressBar.propTypes = {
  percent: Proptypes.number,
  maxTxt: Proptypes.any,
  minTxt: Proptypes.any,
};
