import React, { useContext, useReducer } from "react";
import Proptypes from "prop-types";
import { ethers } from "ethers";

const initialState = {
  bnbSent: "",
  allowListPhaseOne: false,
  allowListPhaseTwo: false,
  investedPhaseOne: "",
  investedPhaseTwo: "",
  unclaimedAmount: "",
  maxBnbPhaseOne: ethers.utils.parseEther("1142.85").toString(),
  maxBnbPhaseTwo: ethers.utils.parseEther("1142.85").toString(),
  maxBnbPerUserPhaseOne: ethers.utils.parseEther("10").toString(),
  maxBnbPerUserPhaseTwo: ethers.utils.parseEther("5").toString(),
  tokenPrice: "",
};

export const PublicSaleStateContext = React.createContext(initialState);
export const PublicSaleDispatchContext = React.createContext(() => { });

export const usePublicSaleStateContext = () =>
  useContext(PublicSaleStateContext);
export const usePublicSaleDispatchContext = () =>
  useContext(PublicSaleDispatchContext);

const ACTION_RESET = "reset";
const ACTION_SET_BNBSEND = "set-bnb-sent";
const ACTION_SET_TOKEN_PRICE = "set-token-price";
const ACTION_SET = "set";

export const resetPublicSaleContext = () => ({ type: ACTION_RESET });
export const setBnbSent = (payload) => ({ type: ACTION_SET_BNBSEND, payload });
export const setTokenPrice = (payload) => ({
  type: ACTION_SET_TOKEN_PRICE,
  payload,
});
export const setPublicSaleContext = (payload) => ({
  type: ACTION_SET,
  payload,
});

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET_BNBSEND:
      return { ...state, bnbSent: action.payload };
    case ACTION_SET:
      return { ...state, ...action.payload };
    case ACTION_SET_TOKEN_PRICE:
      return { ...state, tokenPrice: action.payload };
    case ACTION_RESET:
      return initialState;
    default:
      console.log(action);
      throw new Error("Bad Action Type");
  }
}

function PublicSaleContextProvier({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <PublicSaleDispatchContext.Provider value={dispatch}>
      <PublicSaleStateContext.Provider value={state}>
        {children}
      </PublicSaleStateContext.Provider>
    </PublicSaleDispatchContext.Provider>
  );
}
PublicSaleContextProvier.propTypes = { children: Proptypes.any };

export default PublicSaleContextProvier;
