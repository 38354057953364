import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import Arrow from "../icons/accordionArrow.svg";

const StyledAccordion = styled.div``;

const StyledAccordionHeader = styled.div`
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: ${({ active }) => (active ? "15px 15px 0px 0px" : "15px")};
  transition: all 0.2s;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 35px;
  cursor: pointer;
  word-break: break-all;
  user-select: none;
`;

const StyledAccordionHeaderText = styled.div`
  color: ${({ theme }) => theme.fg1};
  font: normal normal 300 16px/23px Circe;
`;

const StyledAccordionBody = styled.div`
  background: ${({ theme }) => theme.bg3} 0% 0% no-repeat padding-box;
  color: ${({ theme }) => theme.fg1};
  border-radius: 0px 0px 15px 15px;
  padding: 30px;
  word-break: break-all;
  font: normal normal 300 16px/23px Circe;
`;

const Icon = styled.img`
  font-size: 18px;
  color: #08a0f7;
  margin-left: 15px;
  transform: rotate(${(props) => props.rotation}deg);
  transition: all 0.2s;
`;

function Accordion({ title, children, initialActive, style }) {
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (typeof initialActive !== "undefined") {
      setActive(initialActive);
    }
  }, [initialActive]);

  return (
    <StyledAccordion style={style}>
      <StyledAccordionHeader
        active={active}
        onClick={() => {
          setActive(!active);
        }}
      >
        <StyledAccordionHeaderText>{title}</StyledAccordionHeaderText>
        <Icon src={Arrow} rotation={active ? 180 : 0} />
      </StyledAccordionHeader>
      {active && <StyledAccordionBody>{children}</StyledAccordionBody>}
    </StyledAccordion>
  );
}
Accordion.propTypes = {
  title: Proptypes.string,
  children: Proptypes.string,
  initialActive: Proptypes.bool,
  style: Proptypes.any,
};

export default Accordion;
