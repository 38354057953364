import Torus from "@toruslabs/torus-embed";

export default async function prepareTorus() {
  const torus = new Torus();

  window.torus = torus;
  await torus.init({
    enableLogging: true, // default: false
    network: {
      host: process.env.REACT_APP_NETWORK, // default: mainnet
      chainId: process.env.REACT_APP_TORUS_CHAINID, // default: 1
      networkName: process.env.REACT_APP_TORUS_NETWORK_NAME, // default: Main Ethereum Network
    },
    showTorusButton: false,
  });

  return torus;
}
