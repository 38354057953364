import { BigNumber, ethers } from "ethers";
import { MYTV, MyTvContracts } from "../lib/MytvContracts";
import {
  GOVERNANCE_CONTRACT_ADDRESS,
  PANCAKESWAP_API,
  INITIAL_SUPPLY,
  POOL_FARMING_BLOCKS_PER_YEAR,
  PANCAKE_POOL_ADDRESS,
} from "./constants";

let bnbTokenPriceStatic = null;
async function getBnbTokenPrice() {
  if (bnbTokenPriceStatic) return bnbTokenPriceStatic;
  /**
   * @TODO GOVERNANCE_CONTRACT_ADDRESS utiliser cette constante en prod et en dev
   */
  try {
    const uri = `${PANCAKESWAP_API}0x8a682cc16df6574801ae578c3858f0dac44398c7`;
    const response = await fetch(uri);
    const content = await response.json();
    bnbTokenPriceStatic = Number(content.data.price_BNB);
    return bnbTokenPriceStatic;
  } catch (err) {
    console.error(err);
  }
  return -1;
}

let tokenPriceStatic = null;
async function getTokenPrice() {
  if (tokenPriceStatic) return tokenPriceStatic;
  try {
    const uri = `${PANCAKESWAP_API}0x8a682cc16df6574801ae578c3858f0dac44398c7`;
    const response = await fetch(uri);
    const content = await response.json();
    tokenPriceStatic = Number(content.data.price);
    return tokenPriceStatic;
  } catch (err) {
    console.error(err);
  }
  return -1;
}

async function getStakingTokenPrice() {
  const myTvContracts = new MyTvContracts();
  const balancePancakePoolBnbAsync = myTvContracts.provider.getBalance(PANCAKE_POOL_ADDRESS);
  const balancePancakePoolMtvAsync = myTvContracts.governance.balanceOf(PANCAKE_POOL_ADDRESS);
  const bnbTokenPriceAsync = getBnbTokenPrice();
  const totalSupplyAsync = myTvContracts.poolFarming.totalSupply();

  const [
    balancePancakePoolBnb,
    balancePancakePoolMtv,
    bnbTokenPrice,
    totalSupply
  ] = await Promise.all([
    balancePancakePoolBnbAsync,
    balancePancakePoolMtvAsync,
    bnbTokenPriceAsync,
    totalSupplyAsync
  ])

  const amountInBnb = balancePancakePoolMtv.mul(ethers.utils.parseEther(bnbTokenPrice.toString().substr(0, 10)));
  const ttValueInBnb = amountInBnb.add(balancePancakePoolBnb);
  return ttValueInBnb.div(totalSupply);
}

/*
function getFarmAprTest(
  myLpAmount,
  tokenPrice,
  tvlPool,
) {
  
  const y = (myLpAmount * tokenPrice * 100) / tvlPool;
  const z = y * 100 / POOL_FARMING_BLOCKS_PER_YEAR * tokenPrice;

  const apr = z * 100 / myl;

  try {
    return apr.toNumber();
  } catch (e) {
    console.error(e);
    return null;
  }
}
*/
function getFarmApr(
  stakingTokenPrice,
  bnbTokenPrice,
  farmingBalance,
  tokenPerBlock,
) {
  const totalRewardPricePerYear = ethers.utils.parseEther(bnbTokenPrice.toString().substr(0, 10))
    .mul(tokenPerBlock)
    .mul(POOL_FARMING_BLOCKS_PER_YEAR);
  const totalStakingTokenInPool = BigNumber.from(stakingTokenPrice).mul(farmingBalance);
  const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).mul(100);
  try {
    return apr.toNumber();
  } catch (e) {
    console.error(e);
    return null;
  }
}

function getFarmApy(farmApr) {
  return (Math.pow(1 + ((farmApr / 100) / 12), 12) - 1) * 100;
}

export const fetchMyTvKpi = async () => {
  const myTvContracts = new MyTvContracts();
  const [
    tokenPrice,
    staked,
    totalSupply,
    stakingTokenPrice,
    farmingBalance,
    tokenPerBlock,
    totalVestingLocked,
    totalPrivateVestingLocked,
    totalMyTvFarmingLocked,
    totalBnbFarmingLocked,
    bnbTokenPrice,
    circulatingSupply
  ] = await Promise.all([
    getTokenPrice(),
    myTvContracts.totalStake(),
    myTvContracts.totalSupply(),
    getStakingTokenPrice(),
    myTvContracts.getFarmingBalance(),
    myTvContracts.getTokenPerBlock(),
    myTvContracts.totalVestingLocked(),
    myTvContracts.totalPrivateVestingLocked(),
    myTvContracts.totalMyTvFarmingLocked(),
    myTvContracts.totalBnbFarmingLocked(),
    getBnbTokenPrice(),
    myTvContracts.circulatingSupply()
  ]);

  const supplyStakedBig = totalMyTvFarmingLocked
    .add(ethers.utils.parseUnits(staked, MYTV))
    .add(totalVestingLocked).add(totalPrivateVestingLocked)
    .mul(ethers.utils.parseUnits("100", MYTV))
    .div(ethers.utils.parseUnits(INITIAL_SUPPLY, MYTV));

  const supplyStaked = ethers.utils.formatUnits(supplyStakedBig, MYTV);

  const marketCap = tokenPrice ? parseFloat(ethers.utils.formatUnits(circulatingSupply, MYTV)) * tokenPrice : null;

  let farmApr = "N/A";
  let farmAprRes = "N/A";
  let farmingApyRes = "N/A";

  try {
    farmApr = getFarmApr(stakingTokenPrice, bnbTokenPrice, farmingBalance, tokenPerBlock);
    farmAprRes = farmApr.toFixed(2);
    farmingApyRes = getFarmApy(farmApr).toFixed(2);
  } catch (e) {
    console.error(e);
  }

  const tvlFarmedMytv = parseFloat(ethers.utils.formatUnits(totalMyTvFarmingLocked, MYTV)) * tokenPrice;

  const bnbUsd = tokenPrice / bnbTokenPrice;
  const tvlFarmedBnb = parseFloat(ethers.utils.formatUnits(totalBnbFarmingLocked, 18)) * bnbUsd;
  const tvlFarmed = tvlFarmedMytv + tvlFarmedBnb;
  const tvl = (Number(INITIAL_SUPPLY) * (supplyStaked / 100) * tokenPrice) + tvlFarmed;

  return {
    tokenPrice,
    totalSupply: ethers.utils.formatUnits(totalSupply, MYTV),
    contractAddress: GOVERNANCE_CONTRACT_ADDRESS,
    marketCap,
    supplyStaked,
    tvl,
    tvlFarmed,
    farmingApr: farmAprRes,
    farmingApy: farmingApyRes,
    farmingBalance,
    circulatingSupply: ethers.utils.formatUnits(circulatingSupply, MYTV),
  };
};

export const fetchMyTvPacks = async () => {
  return (new MyTvContracts()).getStakingPacks();
};

export const fetchUserInfos = async (wallet) => {
  const myTvContracts = new MyTvContracts();

  const [
    tokenPrice,
    userBalance,
    farmingR,
    stakingR,
    staking,
    myVestingLock,
    myPrivateVestingLock,
  ] = await Promise.all([
    getTokenPrice(),
    myTvContracts.balanceOf(wallet),
    myTvContracts.totalUserPendingFarmingReawards(wallet),
    myTvContracts.totalUserStakingReawards(wallet),
    myTvContracts.totalUserStakings(wallet),
    myTvContracts.myVestingLock(wallet),
    myTvContracts.myPrivateVestingLock(wallet),
  ]);

  const farming = await myTvContracts.totalUserFarming(wallet, tokenPrice);
  const totalTokensRewards = Number(farmingR) + Number(stakingR);
  const totalRewards = tokenPrice ? (~~(totalTokensRewards * tokenPrice * 100) / 100) : 'N/A';
  const balance = tokenPrice ? (~~(Number(userBalance) * tokenPrice * 100) / 100) : 'N/A';
  let totalTvl = 'N/A';

  if (tokenPrice) {
    const vestingLockedNum$ = Number(ethers.utils.formatUnits(myVestingLock, MYTV)) * tokenPrice;
    const privateVestingLockedNum$ = Number(ethers.utils.formatUnits(myPrivateVestingLock, MYTV)) * tokenPrice;
    totalTvl = (Number(staking) * tokenPrice) + farming + vestingLockedNum$ + privateVestingLockedNum$;
  }

  return {
    tokenPrice,
    balanceTokens: userBalance,
    totalTokensRewards,
    totalRewards,
    balance,
    totalTvl,
  };
};
