import React from "react";
import { DownOutlined } from "@ant-design/icons";
import styled from "styled-components";
import Proptypes from "prop-types";
import { Menu, Dropdown as AntdDropdown } from "antd";

const StyledCustomMenu = styled(Menu)`
  border-radius: 15px;
  width: 138px;
  padding: 10px 5px;
  background: ${({ theme }) => theme.ddBg};
  border: 1px solid #2e86fa;

  img {
    width: 28px;
    margin: 0 20px 0 -10px;
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  .ant-dropdown-menu-title-content {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font: normal normal bold 18px/26px Circe;
    color: ${({ theme }) => theme.dd1};
    padding: 5px;
    width: 128px;
    height: 38px;
  }
  &:hover {
    background: ${({ theme }) => theme.ddHoverBg};
    border-radius: 30px;
    div {
      color: ${({ theme }) => theme.dd1};
    }
  }
`;

const Icon = styled(DownOutlined)`
  color: ${({ theme }) => theme.fg1};
  margin-left: 9px;
`;

const StyledClickable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
`;

function DropDown({ onChange, options, children }) {
  return (
    <AntdDropdown
      overlay={
        <StyledCustomMenu
          onClick={(key) =>
            onChange && onChange(options.find((e) => e.id === key.key))
          }
        >
          {(options || []).map((e) => (
            <StyledMenuItem key={e.id}>
              <img src={e.url} alt="icon" />
              <div>{e.label}</div>
            </StyledMenuItem>
          ))}
        </StyledCustomMenu>
      }
      trigger={["click"]}
    >
      <StyledClickable align="middle">
        {children}
        <Icon style={{ fontSize: 17 }} />
      </StyledClickable>
    </AntdDropdown>
  );
}
DropDown.propTypes = {
  onChange: Proptypes.any,
  options: Proptypes.array.isRequired,
  children: Proptypes.any,
};
export default DropDown;
