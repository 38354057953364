import React, { useState } from "react";
import styled, { createGlobalStyle, useTheme } from "styled-components";
import { Col, Row } from "antd";

import Logo from "../icons/logoFull.svg";
import LogoDark from "../icons/logoFullDark.svg";
import LogoSimple from "../icons/logo.svg";
import {
  THEMES,
  PUBLIC_SALE_START_DATE,
} from "../utils/constants";
import CountdownDate from "../lib/CountdownDate";
import PublicSaleCondtionsMobile from "../components/publicSale/PublicSaleCondtionsMobile";

const StyledPage = styled.div`
  background-image: url("/images/dashboard-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom;
  background-color: ${({ theme }) => theme.bg1};
  /* display: flex; */
  /* flex-direction: row; */
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000000;
  overflow: auto;
  max-height: 100vh;
  padding-bottom: 100px;
`;

const StyledMobileScreen = styled.div`
  width: 414px;
  height: 100%;
  margin: auto;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 32px 0;
`;

const StyledSaleArea = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.bg2};
  color: ${({ theme }) => theme.fg1};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 17px 22px;
`;

const StyledTitle = styled.div`
  color: ${({ theme }) => theme.fg1};
  margin-top: 10px;

  font: normal normal bold 25px Circe;

  text-align: center;
`;

const StyledCondtionText = styled.div`
  border-left: 2px solid #2e86fb;
  padding-left: 16px;
  padding-top: 10px;
  font: normal normal 300 16px/16px Circe;
  color: ${({ theme }) => theme.fg4};
  text-align: center;
  margin: 0 auto 15px;
  width: 80%;
`;
const StyledCondtionTextItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 10px;

  span:first-child {
    text-align: left;
  }
  span:last-child {
    text-align: right;
  }
`;

const StyledCondtionSubTitle = styled.div`
  color: ${({ theme }) => theme.fg4};
  font-weight: bold;
  text-align: left;
  margin-bottom: 20px;
`;

const GlobalStyle = createGlobalStyle`
body, #root{
overflow: hidden;

}
`;

function PublicScreenMobile() {
  //
  // CHANGE COUNTER TEXT 'TO BE ANNOUCED'
  //
  const changeCounterAnnouced = true;
  const theme = useTheme();

  const isDefaultSellStarted = PUBLIC_SALE_START_DATE <= new Date();
  const [, setSaleStarted] = useState(isDefaultSellStarted);
  const [firstCountertext, setFirstCountertext] = useState("Start In");

  return (
    <StyledPage>
      <GlobalStyle />
      <StyledMobileScreen>
        <StyledHeader>
          <img src={theme.id === THEMES.LIGHT ? Logo : LogoDark} alt="logo" />
        </StyledHeader>
        <StyledSaleArea>
          <Row gutter={[24, 24]} justify="center">
            <Col>
              <StyledTitle>
                {" "}
                <img
                  src={theme.id === THEMES.LIGHT ? LogoSimple : LogoSimple}
                  alt="logo"
                  style={{ height: 33, width: "auto", marginRight: 24 }}
                />
                {/* 
                <Logo
                      style={{ height: 33, width: "auto", marginRight: 24 }}
                    /> */}
                Public sale phase
              </StyledTitle>{" "}
            </Col>{" "}
            <Col span={24}>
              <CountdownDate
                dateTo={PUBLIC_SALE_START_DATE}
                text={changeCounterAnnouced ? "Start In" : firstCountertext}
                onEnd={() => {
                  setSaleStarted(true);
                  setFirstCountertext("End");
                }} />
            </Col>
            <Col span={24}>
              {" "}
              <PublicSaleCondtionsMobile
                title="Phase 1 : 8th Feb."
                info="* Phase 1 participants can also participate in Phase 2"
              >
                <StyledCondtionText>
                  <StyledCondtionSubTitle>
                    Pool information :
                  </StyledCondtionSubTitle>
                  <StyledCondtionTextItem>
                    {" "}
                    <span> Participants</span> <span>Few amount of winners (TBA)</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Min. Allocation</span> <span>0 BNB</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Max. Allocation</span> <span>TBA</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Hard Cap</span> <span>No hardcap</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Price</span> <span>0.12$</span>{" "}
                  </StyledCondtionTextItem>
                </StyledCondtionText>
                <StyledCondtionText>
                  <StyledCondtionSubTitle>
                    Token information :
                  </StyledCondtionSubTitle>
                  <StyledCondtionTextItem>
                    {" "}
                    <span> Name</span> <span> MyTVchain - GUARANTEED</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Total supply</span> <span>390,000,000</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> ICO sale </span> <span>4,000,000</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Symbol</span> <span>MYTV</span>{" "}
                  </StyledCondtionTextItem>
                </StyledCondtionText>{" "}
              </PublicSaleCondtionsMobile>
            </Col>
            <Col span={24}>
              {" "}
              <PublicSaleCondtionsMobile
                title="Phase 2 : 9th Feb."
                info={false}
              >
                <StyledCondtionText>
                  <StyledCondtionSubTitle>
                    Pool information :
                  </StyledCondtionSubTitle>
                  <StyledCondtionTextItem>
                    {" "}
                    <span> Participants</span> <span> NO LIMIT</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Min. Allocation</span> <span>0 BNB</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Max. Allocation</span> <span>TBA</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Hard Cap</span> <span>480.000$</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Price</span> <span>0.12$</span>{" "}
                  </StyledCondtionTextItem>
                </StyledCondtionText>
                <StyledCondtionText>
                  <StyledCondtionSubTitle>
                    Token information :
                  </StyledCondtionSubTitle>
                  <StyledCondtionTextItem>
                    {" "}
                    <span> Name</span> <span> MyTVchain - FCFS</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Total supply</span> <span>390,000,000</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> ICO sale </span> <span>4,000,000</span>{" "}
                  </StyledCondtionTextItem>

                  <StyledCondtionTextItem>
                    {" "}
                    <span> Symbol</span> <span>MYTV</span>{" "}
                  </StyledCondtionTextItem>
                </StyledCondtionText>{" "}
              </PublicSaleCondtionsMobile>
            </Col>
          </Row>
        </StyledSaleArea>
      </StyledMobileScreen>
    </StyledPage>
  );
}

export default PublicScreenMobile;
