import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

import MyTvButton from "./MyTvButton";
import Arrow from "../icons/rightArrow.svg";
import { THEMES } from "../utils/constants";

const StyledWrapper = styled.div`
  border-radius: 15px;
  background: ${({ theme }) =>
    theme.id === THEMES.LIGHT
      ? "transparent linear-gradient(322deg, #ffffff 0%, #eaf1fd 100%) 0% 0% no-repeat padding-box"
      : "transparent linear-gradient(321deg, #222F5D 0%, #536195 100%) 0% 0% no-repeat padding-box"};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 360px;
  height: 100%;
  padding-bottom: 32px;
`;

const StyledStakingOfferHeader = styled.div`
  background-image: url(${(props) => props.bgImgUrl});
  background-position: center;
  border-radius: 15px 15px 0px 0px;
  background-repeat: no-repeat;
  min-height: 220px;
  width: 360px;
  padding: 30px 30px 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  background-size: cover;
`;

const StyledStakingOfferHeaderTitle = styled.div`
  font: normal normal bold 48px/71px Circe;
  color: #ffffff;
  word-break: break-all;
`;

const StakingOfferBody = styled.div`
  border-left: 3px solid #71c0eb;
  padding-left: 25px;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  width: 250px;
  margin: 30px auto;
`;

const StakingOfferBodyBold = styled.div`
  color: ${({ theme }) => theme.fg3};
  font: normal normal bold 18px/26px Circe;
  word-break: break-all;
`;

const StakingOfferBodyLight = styled.div`
  color: ${({ theme }) => theme.fg3};
  font: normal normal 300 18px/26px Circe;
  word-break: break-all;
`;

export default function StakingOfferCard({
  bgImgUrl,
  title,
  apr,
  lock,
  onBtnClick,
}) {
  return (
    <StyledWrapper>
      <StyledStakingOfferHeader bgImgUrl={bgImgUrl}>
        <StyledStakingOfferHeaderTitle>{title}</StyledStakingOfferHeaderTitle>
      </StyledStakingOfferHeader>
      <StakingOfferBody>
        <StakingOfferBodyBold>{apr}</StakingOfferBodyBold>
        <StakingOfferBodyLight>{lock}</StakingOfferBodyLight>
      </StakingOfferBody>
      <MyTvButton
        style={{ width: "250px" }}
        iconUrl={Arrow}
        onClick={onBtnClick}
      >
        STAKE NOW
      </MyTvButton>
    </StyledWrapper>
  );
}
StakingOfferCard.propTypes = {
  bgImgUrl: PropTypes.string,
  title: PropTypes.string,
  apr: PropTypes.string,
  lock: PropTypes.string,
  onBtnClick: PropTypes.any,
};
