import React from "react";
import Proptypes from "prop-types";
import styled, { css } from "styled-components";
import BasicButton from "./BasicButton";
import { ReactComponent as Loader } from "../icons/loader.svg";

const StyledButton = styled(BasicButton)`
  border-radius: 40px;
  min-height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 20px;
  font-family: Circe;
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  ${({ type, theme }) => {
    switch (type) {
      case "outline":
        return css`
          border: 2px solid #2e86ff;
          color: ${theme.fg1};
        `;
      default:
        return css`
          background: #2e86ff 0% 0% no-repeat padding-box;
          color: #ffffff;
        `;
    }
  }};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
`;

export default function Button({
  children,
  type,
  onClick,
  style,
  loading,
  disabled,
}) {
  return (
    <StyledButton
      type={type}
      onClick={(evt) => {
        if (!disabled && onClick) onClick(evt);
      }}
      style={style}
      $disabled={disabled}
    >
      {loading ? <Loader height="30px" alt="" fill="#ffffff" /> : children}
    </StyledButton>
  );
}

Button.propTypes = {
  children: Proptypes.any,
  type: Proptypes.oneOf(["primary", "outline"]),
  onClick: Proptypes.func,
  style: Proptypes.any,
  loading: Proptypes.bool,
  disabled: Proptypes.bool,
};
