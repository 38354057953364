import React, { useCallback, useContext, useEffect, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import { Link } from "react-router-dom";
import { Col, Row } from "antd";
import Proptypes from "prop-types";
import detectMetamask from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
//import Web3 from "web3";
import Button from "./Button";
import Modal from "./Modal";
import ConnectWalletButton from "./ConnectWalletButton";
import { ReactComponent as Sun } from "../icons/sun.svg";
import { ReactComponent as Crescent } from "../icons/crescent.svg";
import { ReactComponent as FileIcon } from "../icons/file.svg";
import logo from "../icons/logo.svg";
import metaMaskLogo from "../icons/metaMask.png";
//import torusLogo from "../icons/torus.svg";
import walletConnectLogo from "../icons/walletConnect.png";
//import trustWalletLogo from "../icons/trustWallet.png";
import { useThemeDispatchContext } from "../components/providers/ThemeContextProvider";
import { DARK_THEME, LIGHT_THEME } from "../utils/themes";
import setCookie from "../utils/helpers/setCookie";
import {
  PROVIDER_NAMES,
  THEMES,
  PUBLIC_SALE_START_DATE,
} from "../utils/constants";
import { useUserStateContext } from "../components/providers/UserContextProvider";
import useSetupWeb3 from "../utils/hooks/useSetupWeb3";
import useLogout from "../utils/hooks/useLogout";
import {
  setLoginModal,
  useUIDispatchContext,
  useUIStateContext,
} from "../components/providers/UIContextProvider";

const StyledHeader = styled.div`
  width: 100%;
  min-height: 90px;
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  padding: 24px 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledHomeLink = styled(Link)`
  text-align: left;
  font: normal normal bold 22px/32px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  margin-right: 24px;
  display: flex;
  flex-wrap: nowrap;
  user-select: none;

  &:hover {
    color: ${({ theme }) => theme.fg1}; // needed for link color
  }
`;

const StyledThemeSeparatorContainer = styled.div`
  width: 12px;
  height: 20px;
  position: relative;
`;

const StyledThemeSepartor = styled.div`
  height: 23px;
  width: 3px;
  border-radius: 10px;
  position: absolute;
  background-color: ${({ color }) => color};

  transform: translateX(-50%) translateY(-50%) rotate(28deg);
  top: 50%;
  left: 50%;
`;

const StyledThemeBtnsContainer = styled.div`
  display: flex;
  align-items: center;

  cursor: pointer;
  user-select: none;
`;

const StyledFileLink = styled(Row)`
  text-decoration: underline;
  cursor: pointer;
  user-select: none;
  color: ${({ theme }) => theme.fg1};
  display: flex;
  align-items: center;
  justify-content: center;
  :active {
    background-color: rgba(46,134,255,0.1);
    border-radius: 100px;
  }
`;

const StyledClickableIcon = styled.div`
  user-select: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

const StyledHeaderPart = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
`;
/**
 * 
 * @param {string} str 
 * @param {number} leftCharLen 
 * @param {number} rightCharLen 
 * @returns {string} 
 */
function reduceCharLen(str, leftCharLen, rightCharLen) {
  if (str.length < leftCharLen + rightCharLen) {
    return str;
  }

  const start = str.slice(0, leftCharLen);
  const end = str.slice(-rightCharLen);
  return `${start}...${end}`;
}

let intervalTimerId = 0;

function Header({
  children,
  title = "MyTVchain",
  icon = logo,
  link = "/",
  className,
  style,
  addressMaxWidth = 92,
}) {
  const [isModalLocked, setIsModalLocked] = useState(false);
  const themeDispatch = useThemeDispatchContext();
  const { activeAccount } = useUserStateContext();
  const activeTheme = useContext(ThemeContext);
  const { providerName } = useUserStateContext();
  const { loginModalOpen } = useUIStateContext();
  const dispatchUI = useUIDispatchContext();
  const setIsOpen = useCallback(
    (p) => dispatchUI(setLoginModal(p)),
    [dispatchUI]
  );

  const updateTheme = useCallback((theme) => {
    themeDispatch({
      type: "set-theme",
      payload: theme,
    });
    setCookie("theme", theme.id);
  }, [themeDispatch]);

  const setupWeb3 = useSetupWeb3();
  const logout = useLogout();
  useEffect(() => {
    if (activeAccount) setIsOpen(false); // this to close the modal if reconnecting occured naturally
  }, [activeAccount, setIsOpen]);

  const setProvider = (provider, pn) => {
    setupWeb3(provider, pn);
    setCookie("providerName", pn);
    setIsOpen(false);
  };

  const [userCanConnect, setUserCanConnect] = useState(PUBLIC_SALE_START_DATE <= new Date());
  useEffect(() => {
    window.clearInterval(intervalTimerId);
    if (!userCanConnect) {
      intervalTimerId = window.setInterval(() => {
        if (PUBLIC_SALE_START_DATE <= new Date()) {
          window.clearInterval(intervalTimerId);
          setUserCanConnect(true);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalTimerId);
    }
  });

  return (
    <StyledHeader className={className} style={style}>
      <StyledHeaderPart>
        <StyledHomeLink to={link}>
          <img src={icon} alt="" style={{ marginRight: 16 }} />
          {title}
        </StyledHomeLink>

        {children}
      </StyledHeaderPart>

      <Row align="middle" gutter={20} wrap={false}>
        <Col>
          {activeAccount ? (
            <StyledFileLink gutter={14} wrap={false} title={`Copy: ${activeAccount}`} onClick={() => navigator.clipboard.writeText(activeAccount)}>
              <span
                style={{
                  overflow: "hidden",
                  maxWidth: addressMaxWidth,
                  marginRight: 10,
                }}
              >
                {reduceCharLen(activeAccount, 4, 3)}
              </span>
              <FileIcon
                fill={activeTheme.fg1}
              />
            </StyledFileLink>
          ) : null}
        </Col>
        <Col>
          <StyledThemeBtnsContainer
            onClick={() => {
              updateTheme(
                activeTheme.id === THEMES.LIGHT ? DARK_THEME : LIGHT_THEME
              );
            }}
          >
            <StyledClickableIcon style={{ paddingRight: 5 }}>
              <Sun stroke={activeTheme.icon1} />
            </StyledClickableIcon>
            <StyledThemeSeparatorContainer>
              <StyledThemeSepartor color={activeTheme.icon2} />
            </StyledThemeSeparatorContainer>
            <StyledClickableIcon style={{ paddingLeft: 5 }}>
              <Crescent stroke={activeTheme.icon2} />
            </StyledClickableIcon>
          </StyledThemeBtnsContainer>
        </Col>
        <Col>
          <Button disabled={!userCanConnect}
            onClick={async () => {
              if (!activeAccount) {
                setIsOpen(true);
              } else {
                if (providerName === PROVIDER_NAMES.PORTIS) {
                  await window?.portis?.logout();
                }
                logout();
              }
            }}
            type={activeAccount ? "outline" : null}
            style={activeAccount ? { width: 140, } : { width: 226 }}
          >
            {activeAccount ? "LOGOUT" : "CONNECT WALLET"}
          </Button>
        </Col>
      </Row>
      <Modal
        open={loginModalOpen}
        onCancel={() => {
          if (!isModalLocked) setIsOpen(false);
        }}
        title="Connect wallet"
        width={414}
      >
        <div style={{ height: 16 }} />
        <ConnectWalletButton
          iconSrc={metaMaskLogo}
          style={{ margin: "16px auto" }}
          onClick={async () => {
            const provider = await detectMetamask();
            if (provider) {
              await provider.request({ method: "eth_requestAccounts" });
              setProvider(provider, PROVIDER_NAMES.METAMASK);
            }
            // else {
            //   alert("metamask not detected");
            // }
          }}
        >
          MetaMask
        </ConnectWalletButton>

        {/*<ConnectWalletButton
          iconSrc={trustWalletLogo}
          style={{ margin: "16px auto" }}
          onClick={async () => {
            const portis = window?.portis;
            if (!portis) return;
            try {
              setIsModalLocked(true);
              const web3 = new Web3(portis.provider);
              // IMPORTANT: PORTIS REQUIRES TO CALL THIS METHOD TO SHOW LOGIN SCREEN
              await web3.eth.getAccounts();
              setProvider(portis.provider, PROVIDER_NAMES.PORTIS);
            } catch (e) {
              setIsModalLocked(false);
            }
          }}
        >
          Portis
        </ConnectWalletButton>*/}

        {/*<ConnectWalletButton
          iconSrc={torusLogo}
          style={{ margin: "16px auto" }}
          onClick={async () => {
            if (!window?.torus?.login) return;
            setIsModalLocked(true);
            await window.torus.login();
            const { provider } = window.torus;
            setProvider(provider, PROVIDER_NAMES.TORUS);
          }}
        >
          Torus
        </ConnectWalletButton>*/}

        <ConnectWalletButton
          iconSrc={walletConnectLogo}
          style={{ margin: "16px auto" }}
          onClick={async () => {
            try {
              setIsModalLocked(true);
              const provider = new WalletConnectProvider({
                rpc: {
                  56: "https://bsc-dataseed1.binance.org",
                },
                chainId: 56,
                network: "binance",
                qrcode: true,
                qrcodeModalOptions: {
                  mobileLinks: [
                    "metamask",
                    "trust",
                  ]
                }
              });
              provider.networkId = 56;
              await provider.enable();
              setProvider(provider, PROVIDER_NAMES.WALLETCONNECT);
            } catch (e) {
              setIsModalLocked(false);
              console.error(e);
            }
          }}
        >
          Wallet Connect
        </ConnectWalletButton>
      </Modal>
    </StyledHeader>
  );
}

Header.propTypes = {
  children: Proptypes.any,
  title: Proptypes.string,
  icon: Proptypes.any,
  link: Proptypes.any,
  className: Proptypes.string,
  style: Proptypes.any,
  addressMaxWidth: Proptypes.number,
};

export default Header;
