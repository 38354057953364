import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
import style from "./CountdownDate.module.scss"

const StyledWrapper = styled.div`
  background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
  color: ${({ theme }) => theme.fg1};
`;

export default class CountdownDate extends React.Component {
	timerId = 0;

	constructor(props) {
		super(props);
		const { dateTo } = this.props;
		this.state = {
			countDown: CountdownDate.getCountDown(dateTo),
		};
	}

	render() {
		const { countDown } = this.state;
		const { text } = this.props;
		return (
			<StyledWrapper className={style.StyledWrapper}>
				<div className={style.StyledCaption}>{text}</div>
				<div className={style.StyledCounter}>
					{CountdownDate.formatCountDown(countDown)}
				</div>
			</StyledWrapper>
		);
	}

	componentDidMount() {
		this.updateDateTime();
	}

	componentWillUnmount() {
		window.clearTimeout(this.timerId);
	}

	updateDateTime() {
		const { dateTo, onEnd } = this.props;
		const countDown = CountdownDate.getCountDown(dateTo);
		
		if (countDown <= 0) {
			onEnd();
			return;
		}

		this.setState({ countDown });
		this.timerId = window.setTimeout(() => this.updateDateTime(), 1000);
	}

	static getCountDown(dateTo) {
		return new Date(dateTo - new Date());
	}

	static formatCountDown(date) {

		const d = date.getTime() / (1000 * 3600 * 24);

		if(d < 0) return "00:00:00:00";

		const h = (d % 1) * 24;
		const m = (h % 1) * 60;
		const s = (m % 1) * 60;
		return [d, h, m, s].map((i) => (Math.abs(~~i)).toLocaleString("en-US", {
			minimumIntegerDigits: 2,
			useGrouping: false
		})).join(":");
	}
}

CountdownDate.propTypes = {
	dateTo: Proptypes.instanceOf(Date),
	text: Proptypes.string,
	onEnd: Proptypes.func,
};