import { useCallback } from "react";
import {
  resetUserContext,
  useUserDispatchContext,
  useUserStateContext,
} from "../../components/providers/UserContextProvider";
import clearCache from "../helpers/clearCache";
import { PROVIDER_NAMES } from "../constants";

export default function useLogout() {
  const userDisptach = useUserDispatchContext();
  const { providerName } = useUserStateContext();
  return useCallback(async () => {
    if (providerName === PROVIDER_NAMES.TORUS) await window?.torus?.logout();
    clearCache();
    userDisptach(resetUserContext());
  }, [userDisptach, providerName]);
}
