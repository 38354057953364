import React, { useMemo, useState } from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import { Form } from "antd";
import { ethers } from "ethers";
import ContractInfoItem from "../dashboard/ContractInfoItem";
import Input from "../../lib/Input";
import MyTvButton from "../../lib/MyTvButton";
import ProgressBar from "../../lib/ProgressBar";
import wordReducing from "../../utils/helpers/wordReducing";
import BasicButton from "../../lib/BasicButton";
import formatUnits from "../../utils/helpers/formatUnits";
import CountdownDate from "../../lib/CountdownDate";

const StyledWrapper = styled.div`
  height: 100%;
  width: 100%;
  /* should add opacity if current stage isn't 'End In' */
  opacity: ${({ $counterStage }) => ($counterStage !== 1 ? 0.3 : 1)};
`;

const StyledFormBodyWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const StyledFormBodyTitle = styled.div`
  color: #2e86fa;
  font: normal normal bold 20px/29px Circe;
  text-align: center;
  margin-top: 18px;
`;

const StyledFormBodyAdress = styled.div`
  color: ${({ theme }) => theme.fg1};
  font: normal normal bold 18px/26px Circe;
  text-align: center;
  margin-top: 3px;
`;
const StyledAddress = styled.div`
  text-decoration: underline;
  max-width: 100px;
  margin-right: 10px;
`;

const StyledBNBSend = styled.span`
  text-decoration: underline;
`;

const StyledInvestedAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: #2e86fb;
  font: normal normal bold 16px/23px Circe;
  margin: 19px 0 9px 0;
`;

const StyledInputCaption = styled.div`
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${({ theme }) => theme.fg1};
  font: normal normal 300 14px/20px Circe;
`;
const StyledForm = styled(Form)`
  .ant-form-item-control {
    flex-direction: column-reverse;
  }
  .ant-form-item-has-error {
    .ant-input-affix-wrapper,
    Input {
      background: #faefef !important;
    }
  }
`;

const StyledFormItem = styled(Form.Item)`
  margin-bottom: 0;
  .ant-form-item-explain {
  }

  .ant-form-item-explain-error {
    text-align: center;
  }
`;

const StyledPercentageCounterContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const COUNTER_STAGES = { START_IN: 0, END_IN: 1, END: 2 };

/**
 * @description Calculate the max of amount can be bought 
 * @param {string} balance bigNumber
 * @param {string} invested bigNumber
 * @param {string} maxInvestable bigNumber
 * @returns {string} bigNumber
 */
function getMaxInvestingAmount(balance, invested, maxInvestable) {
  balance = balance || "0";
  maxInvestable = maxInvestable || "0";
  invested = invested || "0";
  const max = ethers.BigNumber.from(balance);
  const max2 = ethers.BigNumber.from(maxInvestable).sub(invested);
  if (ethers.BigNumber.from(max).gt(max2)) {
    return max2;
  }

  return max;
}

export default function PublicSaleForm({
  maxBnb,
  endIn,
  startIn,
  // disabled,
  title,
  bnbSend,
  balance,
  contractAddress,
  whiteListed,
  onCounterEnd,
  investedAmount,
  onSuccess,
  loading,
  maxBnbPerUser,
  tokenPrice,
}) {

  investedAmount = investedAmount || "0";

  const [counterStage, setCounterStage] = useState(COUNTER_STAGES.START_IN);
  const [form] = Form.useForm();
  const counterText = useMemo(
    () => ({ 0: "Start In", 1: "End In", 2: "End" }[counterStage]),
    [counterStage]
  );

  //maxBnb = 1142.85 => maxMyTv = 4000000
  // 1 BNB = 3500 MYTV
  const amountPercent = useMemo(() => {
    if (bnbSend === "") return 0;
    if (maxBnb === "") return 0;
    return (formatUnits(bnbSend) / formatUnits(maxBnb)) * 100;
  }, [maxBnb, bnbSend]);

  /*const formatedMaxMyTv = useMemo(
    () => (maxBnb ? formatUnits(maxBnb) : ""),
    [maxBnb]
  );*/

  const formatedMaxMyTv = "4000000";

  const formatedBnbSent = useMemo(
    () => (bnbSend ? formatUnits(bnbSend) : ""),
    [bnbSend]
  );

  const formatedBalance = useMemo(
    () => (balance ? formatUnits(balance) : ""),
    [balance]
  );

  const formattedTokenPrice = useMemo(
    () => (tokenPrice ? formatUnits(tokenPrice, 4) : ""),
    [tokenPrice]
  );

  return (
    <StyledWrapper
      style={{ height: "100%", width: "100%" }}
      $counterStage={counterStage}
    >
      <StyledPercentageCounterContainer>
        {counterStage === COUNTER_STAGES.START_IN ? (
          <CountdownDate
            key="c1"
            dateTo={startIn}
            text={counterText}
            onEnd={() => setCounterStage(COUNTER_STAGES.END_IN)}
          />
        ) : (
          <CountdownDate
            key="c2"
            dateTo={endIn}
            text={counterText}
            onEnd={() => {
              setCounterStage(COUNTER_STAGES.END);
              if (onCounterEnd) onCounterEnd();
            }}
          />
        )}

        <ProgressBar
          percent={amountPercent}
          maxTxt={`${formatedMaxMyTv} MYTV`}
          minTxt="0 MYTV"
        />
      </StyledPercentageCounterContainer>

      <StyledFormBodyWrapper>
        <StyledForm
          form={form}
          onFinish={() => {
            const amount = form.getFieldValue("amount");
            if (onSuccess) onSuccess({ amount, form });
          }}
        >
          <StyledFormBodyTitle>{title}</StyledFormBodyTitle>
          <StyledFormBodyAdress>
            Address : {whiteListed ? "whitelist" : "no whitelist"}
          </StyledFormBodyAdress>
          {[
            [
              "Contract address",
              <StyledAddress style={{ cursor: "pointer" }} onClick={() => navigator.clipboard.writeText(contractAddress)}>
                {wordReducing(contractAddress)}
              </StyledAddress>,
            ],
            ["BNB sent", <StyledBNBSend>{formatedBnbSent}</StyledBNBSend>],
            ["Token price", `1 BNB = ${formattedTokenPrice} MYTV`],
          ].map((e, i) => (
            <ContractInfoItem
              key={i}
              title={e[0]}
              info={e[1]}
              style={{ minHeight: 30, height: 23 }}
            />
          ))}
          <StyledInvestedAmount>
            <div>Invested amount :</div>
            <div>
              {investedAmount ? formatUnits(investedAmount) : 0} /{" "}
              {maxBnbPerUser ? formatUnits(maxBnbPerUser) : 0} BNB
            </div>
          </StyledInvestedAmount>
          <StyledFormItem
            name="amount"
            rules={[
              {
                validator: (_, value) => {
                  if (!value) return Promise.reject(new Error("required"));
                  const pval = ethers.utils.parseEther(value);
                  const max = getMaxInvestingAmount(balance, investedAmount, maxBnbPerUser);
                  if (pval.lt("0") || pval.gt(max))
                    return Promise.reject(`Please enter a amount between 0 and ${formatUnits(max)}`);
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input
              maxLength={18}
              placeholder="The amount"
              suffix={
                <BasicButton
                  $disableShadows
                  onClick={() => {
                    form.setFieldsValue({ amount: formatUnits(getMaxInvestingAmount(balance, investedAmount, maxBnbPerUser)) });
                  }}
                >
                  Max
                </BasicButton>
              }
              borderWidth={2}
              borderColor={
                !whiteListed || counterStage !== 1
                  ? "rgba(46, 134, 255, 0.5)"
                  : "#2E86FF"
              }
              disabled={!whiteListed || counterStage !== 1}
              isNumber
            />
          </StyledFormItem>

          <StyledInputCaption>
            <div>
              {"<"} 0 et {">"} {maxBnbPerUser ? formatUnits(maxBnbPerUser) : ""}
            </div>
            <div>Balance {formatedBalance} BNB</div>
          </StyledInputCaption>
          <MyTvButton
            disabled={!whiteListed || counterStage !== 1}
            onClick={() => {
              form.submit();
            }}
            loading={loading}
          >
            BUY MYTV
          </MyTvButton>
        </StyledForm>
      </StyledFormBodyWrapper>
    </StyledWrapper>
  );
}
PublicSaleForm.propTypes = {
  maxBnb: Proptypes.string,
  endIn: Proptypes.instanceOf(Date),
  startIn: Proptypes.instanceOf(Date),
  // disabled: Proptypes.bool,
  title: Proptypes.any,
  onCounterEnd: Proptypes.func,
  bnbSend: Proptypes.string,
  tokenPrice: Proptypes.string,
  balance: Proptypes.string,
  contractAddress: Proptypes.string,
  whiteListed: Proptypes.bool,
  investedAmount: Proptypes.string,
  onSuccess: Proptypes.func,
  loading: Proptypes.bool,
  maxBnbPerUser: Proptypes.string,
};
