import { useCallback } from "react";
import Web3 from "web3";
import {
  resetUserContext,
  updateActiveAccount,
  updateActiveChainId,
  updateActiveNetwork,
  useUserDispatchContext,
} from "../../components/providers/UserContextProvider";
import { useWeb3DispatchContext } from "../../components/providers/Web3ContextProvier";

export default function useSetupWeb3() {
  const userDisptach = useUserDispatchContext();
  const dispatchWeb3 = useWeb3DispatchContext();

  return useCallback(
    async (provider, name) => {
      const web3 = new Web3(provider);
      web3.eth.extend({
        methods: [
          {
            name: "chainId",
            call: "eth_chainId",
            outputFormatter: web3.utils.hexToNumber,
          },
        ],
      });

      const accs = await web3.eth.getAccounts();
      if (accs?.length === 0) {
        return null;
      }
      const saveInfos = async () => {
        const accounts = await web3.eth.getAccounts();

        userDisptach(updateActiveAccount(accounts[0]));
        const networkId = await web3.eth.net.getNetworkType();
        userDisptach(updateActiveNetwork(networkId));
        const chainId = await web3.eth.chainId();
        userDisptach(updateActiveChainId(chainId));
      };
      const clean = () => {
        userDisptach(resetUserContext());
      };
      provider.on("close", clean);
      provider.on("disconnect", clean);
      provider.on("accountsChanged", saveInfos);
      provider.on("connect", saveInfos);
      provider.on("chainChanged", saveInfos);
      provider.on("networkChanged", saveInfos);
      saveInfos();

      dispatchWeb3({
        providerName: name,
        provider,
        web3,
      });

      return web3;
    },
    [userDisptach, dispatchWeb3]
  );
}
