import React, { useContext, useMemo } from "react";
import Proptypes from "prop-types";
import { Modal as AntdModal } from "antd";
import styled, { createGlobalStyle, ThemeContext } from "styled-components";

import { ReactComponent as ExitLogo } from "../icons/exit.svg";
import inconsistentColors from "../utils/helpers/inconsistentColors";

const StyledHeaderWrapper = styled.div`
  width: 100%;
  position: relative;
  border-bottom: 1px solid ${({ $borderColor }) => $borderColor};
  text-align: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 12.5px;
  margin-bottom: 15px;
  font: normal normal bold 18px/26px Circe;
  color: ${({ theme }) => theme.fg2};
`;

const StyledCloseBtn = styled.div`
  position: absolute;
  right: 0;
  top: 5%;
  cursor: pointer;
`;

const StyledModal = styled(AntdModal)`
  .ant-modal-content {
    border-radius: 15px;

    background: ${({ theme }) => theme.bg4};
  }
  .ant-modal-close-x {
    display: none;
  }
  .ant-modal-body {
    padding: 24px 48px;
  }
`;

const GS = createGlobalStyle`
  .ant-modal-mask{
  background-color: rgba(21, 29, 64, 0.56);
  }
`;

export default function Modal({
  width,
  title,
  open,
  onCancel,
  children,
  className,
  borderColor,
  headerStyle,
}) {
  const theme = useContext(ThemeContext);
  const defaultBorderDividerColor = useMemo(
    () =>
      inconsistentColors(
        "rgba(107, 114, 142, 0.65)",
        "rgba(84, 91, 122, 0.65)"
      )({ theme }),
    [theme]
  );

  return (
    <StyledModal
      className={className}
      title={null}
      footer={null}
      visible={open}
      onCancel={onCancel}
      width={width}
      centered
    >
      <GS />
      <StyledHeaderWrapper
        style={headerStyle}
        $borderColor={borderColor || defaultBorderDividerColor}
      >
        {title}
        <StyledCloseBtn>
          <ExitLogo fill={theme.fg2} width="20px" onClick={onCancel} />
        </StyledCloseBtn>
      </StyledHeaderWrapper>
      {children}
    </StyledModal>
  );
}

Modal.propTypes = {
  open: Proptypes.bool,
  onCancel: Proptypes.func,
  children: Proptypes.any,
  width: Proptypes.number,
  title: Proptypes.string,
  className: Proptypes.string,
  borderColor: Proptypes.string,
  headerStyle: Proptypes.any,
};
