import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import { ReactComponent as Info } from "../../icons/info.svg";

const StyledWrapper = styled.div`
  line-height: 23px;
  font: normal normal 300 16px/23px Circe;
  color: ${({ theme }) => theme.fg1};
  background:${({ theme }) => theme.bg5}; 0% 0% no-repeat padding-box;
  padding: 5px;
  border-radius: 10px;
`;

const StyledCondtionTitle = styled.div`
  text-align: center;
  font: normal normal bold 26px/39px Circe;
  color: #2e86fa;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 32px 0;
`;
const StyledPhaseInfo = styled.div`
  text-align: center;
  margin: 20px auto;
  width: 80%;
`;
export default function PublicSaleCondtionsMobile({ title, children, info }) {
  return (
    <StyledWrapper>
      <StyledCondtionTitle>
        <Info
          fill="#2e86fa"
          style={{ height: 23, width: "auto", marginRight: 16 }}
        />
        {title}
      </StyledCondtionTitle>
      {children}
      <StyledPhaseInfo>{info}</StyledPhaseInfo>{" "}
    </StyledWrapper>
  );
}
PublicSaleCondtionsMobile.propTypes = {
  title: Proptypes.string,
  children: Proptypes.any,
  info: Proptypes.any,
};
