import { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import detectMetamask from "@metamask/detect-provider";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3 from "web3";
import Dashboard from "./pages/Dashboard";
import Staking from "./pages/Staking";
import Swap from "./pages/Swap";
import StakingDetail from "./pages/StakingDetail";
import Farming from "./pages/Farming";
import Faq from "./pages/Faq";
import { APP_LINKS, PROVIDER_NAMES, PORTIS_CHAINID, WALLETCONNECT_RPC_URL } from "./utils/constants";
import MobileScreen from "./pages/MobileScreen";
import PublicSale from "./pages/PublicSale";
import useSetupWeb3 from "./utils/hooks/useSetupWeb3";
import getCookie from "./utils/helpers/getCookie";
import prepareTorus from "./utils/helpers/prepareTorus";
import preparePortis from "./utils/helpers/preparePortis";
import { fetchMyTvKpi, fetchMyTvPacks, fetchUserInfos } from "./utils/api";
import {
  setMyTvContext,
  setMyTvPacks,
  useMyTvDispatchContext,
} from "./components/providers/MyTvContextProvider";
import {
  userSetKpis,
  useUserDispatchContext,
  useUserStateContext,
} from "./components/providers/UserContextProvider";
import PublicScreenMobile from "./pages/PublicScreenMobile";

function App() {
  const [width, setWidth] = useState(window.innerWidth);
  const dispatchMyTv = useMyTvDispatchContext();
  const { activeAccount } = useUserStateContext();
  const dispatchUser = useUserDispatchContext();
  const setupWeb3 = useSetupWeb3();
  const providerName = getCookie("providerName");

  useEffect(() => {
    const onResize = () => {
      setWidth(document?.body?.clientWidth || window.innerWidth);
    };
    onResize();
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    (async () => {
      switch (providerName) {
        case PROVIDER_NAMES.METAMASK: {
          const provider = await detectMetamask();

          if (provider) {
            setupWeb3(provider, PROVIDER_NAMES.METAMASK);
          }
          break;
        }

        case PROVIDER_NAMES.WALLETCONNECT: {
          const provider = new WalletConnectProvider({
            rpc: {
              [PORTIS_CHAINID]: WALLETCONNECT_RPC_URL,
            }
          });
          await provider.enable();
          setupWeb3(provider, PROVIDER_NAMES.WALLETCONNECT);
          break;
        }
        default: {
          // noop
        }
      }
    })();
  }, [setupWeb3, providerName]);

  useEffect(() => {
    // TORUS
    (async () => {
      const torus =
        !providerName || providerName === PROVIDER_NAMES.TORUS
          ? await prepareTorus()
          : null;
      if (providerName === PROVIDER_NAMES.TORUS) {
        if (torus?.provider) {
          setupWeb3(torus.provider, PROVIDER_NAMES.TORUS);
        }
      }
    })();
  }, [setupWeb3, providerName]);

  useEffect(() => {
    // PORTIS
    (async () => {
      const portis =
        !providerName || providerName === PROVIDER_NAMES.PORTIS
          ? await preparePortis()
          : null;
      if (providerName === PROVIDER_NAMES.PORTIS) {
        const { result: isLoggedIn } = await portis.isLoggedIn();
        if (isLoggedIn) {
          const web3 = new Web3(portis.provider);
          await web3.eth.getAccounts();
          setupWeb3(portis.provider, PROVIDER_NAMES.PORTIS);
        }
      }
    })();
  }, [setupWeb3, providerName]);

  useEffect(() => {
    fetchMyTvKpi()
      .then(async (data) => {
        const {
          tokenPrice,
          totalSupply,
          contractAddress,
          marketCap,
          supplyStaked,
          tvl,
          tvlFarmed,
          farmingApr,
          farmingApy,
          farmingBalance,
          circulatingSupply,
        } = data;

        dispatchMyTv(
          setMyTvContext({
            tokenPrice,
            totalSupply: parseInt(totalSupply, 10),
            contractAddress,
            marketCap,
            supplyStaked:
              supplyStaked !== "N/A"
                ? parseFloat(supplyStaked)
                : supplyStaked,
            tvl,
            tvlFarmed,
            farmingApr,
            farmingApy,
            farmingBalance,
            circulatingSupply,
          })
        );
      })
      .catch(console.log);
    fetchMyTvPacks()
      .then(async (packs) => {
        dispatchMyTv(setMyTvPacks(packs));
      })
      .catch(console.log);
  }, [dispatchMyTv]);

  useEffect(() => {
    if (activeAccount) {
      fetchUserInfos(activeAccount)
        .then(async (data) => {
          const {
            tokenPrice,
            balance,
            balanceTokens,
            totalRewards,
            totalTokensRewards,
            totalTvl,
          } = data;
          dispatchUser(
            userSetKpis({
              tokenPrice,
              balance,
              balanceTokens,
              totalRewards,
              totalTokensRewards,
              totalTvl,
            })
          );
        })
        .catch(console.log);
    }
  }, [activeAccount, dispatchUser]);

  const isPublicSalePath = window.location.pathname === "/public-sale";
  const isMobile = width <= 1024;

  return (
    <BrowserRouter>
      {isMobile && isPublicSalePath ? <PublicScreenMobile /> : null}
      {isMobile && !isPublicSalePath ? <MobileScreen /> : null}

      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path={APP_LINKS.DASHBOARD} element={<Dashboard />} />
        <Route path={APP_LINKS.STAKING} element={<Staking />} />
        <Route path={APP_LINKS.SWAP} element={<Swap />} />
        <Route path={APP_LINKS.STAKING_DETAILS}>
          <Route
            path={`${APP_LINKS.STAKING_DETAILS}/:id`}
            element={<StakingDetail />}
          />
          <Route path={APP_LINKS.STAKING_DETAILS} element={<StakingDetail />} />
        </Route>
        <Route path={APP_LINKS.FARMING} element={<Farming />} />
        <Route path={APP_LINKS.FAQ} element={<Faq />} />
        <Route path={APP_LINKS.PUBLICSALE} element={<PublicSale />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
