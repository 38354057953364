import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Proptypes from "prop-types";
import inconsistentColors from "../utils/helpers/inconsistentColors";

const StyledPopover = styled.div`
  position: absolute;
  border: 1px solid #2e86fb;
  border-radius: 10px;
  color: ${({ theme }) => theme.fg1};
  min-width: 100px;
  min-height: 60px;
  width: fit-content;
  z-index: 999;
`;

const StyledTriangle = styled.div`
  position: absolute;
  left: -10px;
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid #2e86fb;
  transform: translateY(-50%);
  &:after {
    content: "";
    position: absolute;
  }
`;

const StyledLeftTriangle = styled(StyledTriangle)`
  &:after {
    right: -10px;
    top: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid ${({ backgroundColor }) => backgroundColor};
  }
`;

const StyledRightTriangle = styled(StyledTriangle)`
  left: 100%;
  border-right: none;
  border-left: 10px solid #2e86fb;
  &:after {
    left: -10px;
    top: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid ${({ backgroundColor }) => backgroundColor};
  }
`;

const StyledMainContainer = styled.div`
  position: relative;
`;
function Popover({
  children,
  style,
  className,
  content,
  popoverStyle,
  backgroundColor,
  triangleTop = "50%",
}) {
  const [open, setIsOpen] = useState(false);
  const popoverRef = useRef(null);
  const theme = useContext(ThemeContext);

  const [extraStyle, setExtraStyle] = useState({});
  const [triangleStyle, setTriangleStyle] = useState(null);
  const [placement, setPlacement] = useState("left");

  const bc = useMemo(
    () => backgroundColor || inconsistentColors("bg2", "bg5")({ theme }),
    [theme, backgroundColor]
  );

  const triangle = useMemo(() => {
    switch (placement) {
      case "right":
        return (
          <StyledRightTriangle
            backgroundColor={bc}
            style={{ top: triangleTop, ...triangleStyle }}
          />
        );
      case "left":
      default:
        return (
          <StyledLeftTriangle
            backgroundColor={bc}
            style={{ top: triangleTop, ...triangleStyle }}
          />
        );
    }
  }, [bc, triangleTop, triangleStyle, placement]);

  useEffect(() => {
    function setS() {
      const box = popoverRef?.current?.getBoundingClientRect();
      let ppStyle = null;
      const transform = `translateY(calc(-${triangleTop} + 10px))`;
      switch (placement) {
        case "right":
          ppStyle = {
            transform,
            right: "calc(100% + 20px)",
          };
          break;
        case "left":
        default:
          ppStyle = {
            transform,
            left: "calc(100% + 20px)",
          };
          break;
      }

      const top = box?.top || NaN;
      if (top < 0) ppStyle.top = top * -1 + 5;

      const right = box?.right || NaN;
      if (right > window.innerWidth) {
        setPlacement("right");
      } else {
        setTriangleStyle(null);
      }

      setExtraStyle(ppStyle);
    }
    setS();
    document.addEventListener("scroll", setS, true);
    window.addEventListener("resize", setS);
    return () => {
      document.removeEventListener("scroll", setS);
      window.removeEventListener("resize", setS);
    };
  }, [placement, open, triangleTop]);

  return (
    <StyledMainContainer
      style={style}
      className={className}
      onMouseEnter={() => {
        setIsOpen(true);
      }}
      onMouseLeave={() => {
        setIsOpen(false);
      }}
    >
      {open ? (
        <StyledPopover
          ref={popoverRef}
          style={{
            ...extraStyle,
            ...popoverStyle,
            backgroundColor: bc,
            transformOrigin: triangleTop,
          }}
        >
          {content}
          {triangle}
        </StyledPopover>
      ) : null}
      {children}
    </StyledMainContainer>
  );
}
Popover.propTypes = {
  children: Proptypes.any,
  content: Proptypes.any,
  popoverStyle: Proptypes.any,
  backgroundColor: Proptypes.string,
  style: Proptypes.any,
  className: Proptypes.string,
  triangleTop: Proptypes.string,
};

export default Popover;
