import React from "react";
import { useNavigate } from "react-router";
import DashboardCard from "../../lib/DashboardCard";
import MyTvButton from "../../lib/MyTvButton";

import details from "../../icons/details.svg";
import { APP_LINKS } from "../../utils/constants";

import {
  useUserStateContext,
} from "../providers/UserContextProvider";

function StakingFarmingCard() {
  const navigate = useNavigate();
  const { activeAccount } = useUserStateContext();

  return (
    <DashboardCard
      style={{ padding: 32, paddingTop: 13 }}
      title={
        <>
          <img src={details} alt="" style={{ width: 19, marginBottom: 9 }} />
          Your staking and farming details
        </>
      }
      titleStyle={{ paddingBottom: 15 }}
    >
      <MyTvButton
        disabled={!activeAccount}
        onClick={() => {
          navigate(APP_LINKS.STAKING_DETAILS);
        }}
        style={{ width: "100%" }}
      >
        MONITORING
      </MyTvButton>
    </DashboardCard>
  );
}

export default StakingFarmingCard;
