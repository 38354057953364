import { Col, Row } from "antd";
import Proptypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import DashboardKPICard from "../lib/DashboardKPICard";
import StakingDataItem from "../lib/StakingDataItem";
import Page from "../lib/Page";
import { ReactComponent as InfoIcon } from "../icons/info.svg";
import logo from "../icons/logo.svg";
import dicon from "../icons/dashboard.svg";
import ContractInfoItem from "../components/dashboard/ContractInfoItem";
import ContactCard from "../components/dashboard/ContactCard";
import FaqCard from "../components/dashboard/FaqCard";
import StakingFarmingCard from "../components/dashboard/StakingFarmingCard";
import { useUserStateContext } from "../components/providers/UserContextProvider";
import { useMyTvStateContext } from "../components/providers/MyTvContextProvider";
import wordReducing from "../utils/helpers/wordReducing";
import commaSeparatedNumber from "../utils/helpers/commaSeparatedNumber";

import { PUBLIC_SALE_END_DATE } from "../utils/constants";

const StyledDashboardBody = styled.div`
   width: 100%;
   margin-top: 16px;
   display: grid;
   grid-template-columns: minmax(0, auto) minmax(0, 382px);
   grid-template-rows: auto auto auto;
   align-items: stretch;
   gap: 16px;
 `;

const StyledKpiBlock = styled.div`
   background-color: ${({ theme }) => theme.bg2};
   border-radius: 15px;
   padding: 16px 32px 21px 32px;
   text-align: left;
   font: normal normal bold 22px/32px Circe;
   letter-spacing: 0px;
   color: ${({ theme }) => theme.fg1};
   opacity: 1;
   height: 195px;
 `;

const StyledKpiBlock2 = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 15px;
  padding: 19px 32px 16px 16px;
  text-align: left;
  font: normal normal bold 22px/32px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  grid-row: 2 / span 3;
  display: grid;
  grid-template-columns: 43.3% auto;
  grid-template-rows: auto 85px 85px 85px;
  gap: 12px 32px;
  height: 397px;
`;

const StyledInfoCard = styled.div`
   background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
   border-radius: 10px;
   display: flex;
   flex-direction: column;
   align-items: stretch;
   padding: 24px 32px;
   grid-column: 2;
   grid-row: 1 / span 4;
 `;

const StyledDashboardPage = styled(Page)`
  background-image: url("/images/dashboard-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom;
`;

const StyledAddress = styled.span`
  cursor: pointer;
   text-decoration: underline;
   :active {
    background-color: rgba(46,134,255,0.1);
    border-radius: 100px;
  }
 `;

const StyledComingSoonCard = styled.div`
  margin-top: 40px;
  height: 545px;
  background-image: url(${({ $bg }) => $bg});
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  border-radius: 20px;
`;
const StlyedTxt = styled.div`
  position: absolute;
  text-align: left;
  font: normal normal bold 62px/91px Circe;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  top: 326px;
  left: 124px;
`;
const StyledColoredText = styled.div`
  position: absolute;
  top: 200px;
  left: 124px;
  text-align: left;
  font: normal normal bold 72px/106px Circe;
  color: #55c0f0;
  width: 455px;

  border-bottom: 1px solid #55c0f0;
`;


function ComingSoonCard({ text, backgroundImage }) {
  return (
    <StyledComingSoonCard $bg={backgroundImage}>
      <StyledColoredText>{text}</StyledColoredText>
      <StlyedTxt>
        Coming
        <div style={{ marginLeft: 150 }}>Soon</div>
      </StlyedTxt>
    </StyledComingSoonCard>
  );
}
ComingSoonCard.propTypes = {
  backgroundImage: Proptypes.string,
  text: Proptypes.string,
};

let intervalTimerId = 0;

function Dashboard() {
  const activeTheme = useContext(ThemeContext);
  const {
    activeAccount,
    balanceTokens,
    balance,
    totalTokensRewards,
    totalRewards,
    totalTvl
  } = useUserStateContext();
  const {
    tokenPrice,
    contractAddress,
    totalSupply,
    marketCap,
    circulatingSupply,
    supplyStaked,
    tvl
  } = useMyTvStateContext();
  const [canUseView, setCanUseView] = useState(PUBLIC_SALE_END_DATE <= new Date());
  useEffect(() => {
    window.clearInterval(intervalTimerId);
    if (!canUseView) {
      intervalTimerId = window.setInterval(() => {
        if (PUBLIC_SALE_END_DATE <= new Date()) {
          window.clearInterval(intervalTimerId);
          setCanUseView(true);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalTimerId);
    }
  });

  const comingSoonElement = (
    <ComingSoonCard
      backgroundImage="/images/dashboard-cs.png"
      text="DASHBOARD"
    />
  );

  const viewElement = (
    <StyledDashboardBody>
      <StyledKpiBlock>
        <Row gutter={16} style={{ marginBottom: 17 }}>
          <Col>
            <img src={dicon} alt="" />
          </Col>
          <Col>Your dashboard</Col>
        </Row>

        <Row justify="space-between" gutter={[16, 16]}>
          <Col span={8}>
            <DashboardKPICard
              title="Your TVL"
              firstAmount={commaSeparatedNumber(totalTvl ?? 0)}
              unit1="USD"
              disabled={!activeAccount}
            />
          </Col>
          <Col span={8}>
            <DashboardKPICard
              title="Total rewards"
              firstAmount={commaSeparatedNumber(totalTokensRewards ?? 0)}
              secondAmount={commaSeparatedNumber(totalRewards ?? 0)}
              disabled={!activeAccount}
            />
          </Col>
          <Col span={8}>
            <DashboardKPICard
              title="Your wallet"
              firstAmount={commaSeparatedNumber(balanceTokens ?? 0)}
              secondAmount={commaSeparatedNumber(balance ?? 0)}
              disabled={!activeAccount}
            />
          </Col>
        </Row>
      </StyledKpiBlock>

      <StyledKpiBlock2>
        <Row gutter={16} style={{ marginLeft: 8 }}>
          <Col>
            <img src={logo} alt="" />
          </Col>
          <Col>MyTV staking data</Col>
        </Row>
        <StyledInfoCard
          style={{
            gridColumn: 2,
            gridRow: "1 / span 4",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Row
            align="middle"
            justify="center"
            style={{
              marginBottom: 20,
              color: activeTheme.fg3,
            }}
          >
            <InfoIcon
              fill={activeTheme.icon3}
              style={{ height: 18, width: "auto", marginRight: 16 }}
            />
            Contract info
          </Row>
          {[
            ["Market Cap", `$${commaSeparatedNumber(marketCap ?? 0)}`],
            ["Circulating Supply", commaSeparatedNumber(circulatingSupply ?? 0)],
            ["Fully Diluted Market Cap", `$${commaSeparatedNumber(totalSupply * tokenPrice)}`],
            ["Total supply", commaSeparatedNumber(totalSupply ?? 0)],
            [
              "Address",
              <StyledAddress onClick={() => navigator.clipboard.writeText(contractAddress)} >
                {wordReducing(contractAddress || "")}
              </StyledAddress>,
            ],
            //["Holders", "N/A"],
          ].map((e, i) => (
            <ContractInfoItem key={i} title={e[0]} info={e[1]} />
          ))}
        </StyledInfoCard>
        <StakingDataItem value={`~ $${commaSeparatedNumber(tokenPrice ?? 0, 4)}`} text="MYTV rate" />
        <StakingDataItem value={`$${commaSeparatedNumber(tvl ?? 0)}`} text="TVL" />
        <StakingDataItem value={supplyStaked ? `${commaSeparatedNumber(supplyStaked)}%` : "...."} text="supply staked" />
      </StyledKpiBlock2>
      <StakingFarmingCard />
      <ContactCard />
      <FaqCard />
    </StyledDashboardBody>
  );

  return (
    <StyledDashboardPage header={<DashboardHeader />} requireLoggedIn={false}>
      {canUseView ? viewElement : comingSoonElement}
    </StyledDashboardPage>
  );
}

export default Dashboard;
