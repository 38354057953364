import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import inconsistentColors from "../utils/helpers/inconsistentColors";
import BasicButton from "./BasicButton";

const StyledLinkItem = styled(BasicButton)`
  border: 1px solid #2e86ff;
  border-radius: 60px;
  width: 318px;
  height: 55px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
`;

const StyledIcon = styled.img`
  margin-right: 15px;
  max-height: 100%;
  max-width: 100%;
`;

const StyledText = styled.div`
  color: ${inconsistentColors("fg3", "fg1")};
  font: normal normal bold 16px/23px Circe;
`;

export default function ConnectWalletButton({
  onClick,
  iconSrc,
  children,
  style,
  className,
}) {
  return (
    <StyledLinkItem onClick={onClick} style={style} className={className}>
      <StyledIcon src={iconSrc} alt="icon" />
      <StyledText>{children}</StyledText>
    </StyledLinkItem>
  );
}

ConnectWalletButton.propTypes = {
  iconSrc: Proptypes.string,
  children: Proptypes.any,
  style: Proptypes.any,
  className: Proptypes.string,
  onClick: Proptypes.func,
};
