import styled, { css } from "styled-components";

const BasicButton = styled.div`
  user-select: none;
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};

  ${({ $disabled, $loading, $disableShadows = false }) => {
    if ($disableShadows) return "";
    return !$disabled && !$loading
      ? css`
          &:hover {
            opacity: 0.95;
          }
          &:active {
            box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
          }
        `
      : "";
  }}
`;

export default BasicButton;
