import React from "react";
import styled, { createGlobalStyle, useTheme } from "styled-components";
import { Col, Row } from "antd";

import DashboardKPICard from "../lib/DashboardKPICard";
import Logo from "../icons/logoFull.svg";
import LogoDark from "../icons/logoFullDark.svg";
import dicon from "../icons/dashboard.svg";
import { useUserStateContext } from "../components/providers/UserContextProvider";
import { THEMES } from "../utils/constants";

const StyledPage = styled.div`
  background-image: url("/images/dashboard-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom;
  background-color: ${({ theme }) => theme.bg1};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  z-index: 1000000;
`;

const StyledMobileScreen = styled.div`
  width: 414px;
  height: 736px;
`;

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 40px 0 32px 0;
`;

const StyledDashboardHeader = styled.div`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const StyledMessage = styled.div`
  font: normal normal bold 18px/26px Circe;
  margin-top: 36px;
  text-align: center;
  color: ${({ theme }) => theme.fg1};
`;

const StyledKpiBlock = styled.div`
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 15px;
  padding: 23px 32px;
  text-align: left;
  font: normal normal bold 22px/32px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  height: 472px;
  max-width: 382px;
  width: calc(100% - 30px);
  min-width: 250px;
  margin: 0 auto;
`;

const GlobalStyle = createGlobalStyle`
body, #root{
overflow: hidden;

}
`;

function MobileScreen() {
  const { activeAccount, balanceTokens, totalTokensRewards, totalTvl } =
    useUserStateContext();
  const theme = useTheme();

  return (
    <StyledPage>
      <GlobalStyle />
      <StyledMobileScreen>
        <StyledHeader>
          <img src={theme.id === THEMES.LIGHT ? Logo : LogoDark} alt="logo" />
          <StyledMessage>
            Your dashboard is not available on mobile <br /> for now, please use
            it on computer
          </StyledMessage>
        </StyledHeader>
        <StyledKpiBlock>
          <StyledDashboardHeader disabled={!activeAccount}>
            <Row gutter={16} justify="center">
              <Col>
                <img src={dicon} alt="" />
              </Col>
              <Col style={{ fontSize: "18px" }}>Your dashboard</Col>
            </Row>
          </StyledDashboardHeader>
          <br />
          <Row justify="space-between" gutter={[16, 18]}>
            <Col span={24}>
              <DashboardKPICard
                title="Total TVL staked"
                firstAmount={totalTvl}
                secondAmount="...."
                disabled={!activeAccount}
              />
            </Col>
            <Col span={24}>
              <DashboardKPICard
                title="Total rewards"
                firstAmount={totalTokensRewards}
                secondAmount="...."
                disabled={!activeAccount}
              />
            </Col>
            <Col span={24}>
              <DashboardKPICard
                title="Your wallet"
                firstAmount={balanceTokens || ""}
                secondAmount="...."
                disabled={!activeAccount}
              />
            </Col>
          </Row>
        </StyledKpiBlock>
      </StyledMobileScreen>
    </StyledPage>
  );
}

export default MobileScreen;
