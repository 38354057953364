import React, { useContext, useReducer } from "react";
import Proptypes from "prop-types";

const initialState = {
  loginModalOpen: false,
};

export const UIStateContext = React.createContext(initialState);
export const UIDispatchContext = React.createContext(() => {});

export const useUIStateContext = () => useContext(UIStateContext);
export const useUIDispatchContext = () => useContext(UIDispatchContext);

const ACTION_RESET = "reset";
const ACTION_SET_LOGIN_MODAL = "set-login-modal";
const ACTION_TOGGLE_LOGIN_MODAL = "toggle-login-modal";

export const resetUIContext = () => ({ type: ACTION_RESET });
export const toggleLoginModal = () => ({ type: ACTION_TOGGLE_LOGIN_MODAL });
export const setLoginModal = (payload) => ({
  type: ACTION_SET_LOGIN_MODAL,
  payload,
});

function reducer(state, action) {
  switch (action.type) {
    case ACTION_SET_LOGIN_MODAL:
      return { ...state, loginModalOpen: action.payload };
    case ACTION_TOGGLE_LOGIN_MODAL:
      return { ...state, loginModalOpen: !state.loginModalOpen };
    case ACTION_RESET:
      return initialState;
    default:
      console.log(action);
      throw new Error("Bad Action Type");
  }
}

function UIContextProvier({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UIDispatchContext.Provider value={dispatch}>
      <UIStateContext.Provider value={state}>
        {children}
      </UIStateContext.Provider>
    </UIDispatchContext.Provider>
  );
}
UIContextProvier.propTypes = { children: Proptypes.any };

export default UIContextProvier;
