import { useCallback } from "react";
import {
  userSetKpis,
  useUserDispatchContext,
  useUserStateContext,
} from "../../components/providers/UserContextProvider";
import { fetchUserInfos } from "../api";

export default function useUpdateMyTvBalance() {
  const userDisptach = useUserDispatchContext();
  const { activeAccount } = useUserStateContext();

  return useCallback(async () => {
    if (activeAccount)
      fetchUserInfos(activeAccount)
        .then(async (res) => {
          if (res.ok) {
            const {
              data: {
                balance,
                balanceTokens,
                totalRewards,
                totalTokensRewards,
                totalTvl,
              },
            } = await res.json();
            userDisptach(
              userSetKpis({
                balance,
                balanceTokens,
                totalRewards,
                totalTokensRewards,
                totalTvl,
              })
            );
          }
        })
        .catch(console.log);
  }, [userDisptach, activeAccount]);
}
