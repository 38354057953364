import React, { useMemo } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

const LinkItem = styled(Link)`
  transition: all 0.2s;
  border: 2px solid
    ${({ $active, theme }) => ($active ? "#2E86FF" : theme.border1)};
  border-radius: 40px;
  opacity: 1;
  height: 42px;
  padding: 2px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: left;
  font: normal normal bold 22px/32px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  background-color: ${({ theme }) => theme.bg2};
  user-select: none;
`;

export default function HeaderLinkItem({ children, to = "" }) {
  const location = useLocation();
  const active = useMemo(
    () => to && location.pathname.includes(to),
    [location, to]
  );
  return (
    <LinkItem $active={active} to={to}>
      {children}
    </LinkItem>
  );
}
HeaderLinkItem.propTypes = {
  to: PropTypes.string,
  children: PropTypes.any,
};
