import React from "react";
import styled from "styled-components";
import Proptypes from "prop-types";
import { ReactComponent as Info } from "../../icons/info.svg";

const StyledWrapper = styled.div`
  margin-bottom: 35px;
  line-height: 23px;
  font: normal normal 300 16px/23px Circe;
  color: ${({ theme }) => theme.fg1};
  background:${({ theme }) => theme.bg5}; 0% 0% no-repeat padding-box;
  padding: 60px 25px;
  border-radius: 10px;
  width: 100%;
`;

const StyledCondtionTitle = styled.div`
  text-align: center;
  font: normal normal bold 26px/39px Circe;
  color: #2e86fa;
  display: flex;
  flex-direction: col;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
`;

const StyledCondtionFooter = styled.div`
  text-align: left;
  display: flex;
  flex-direction: col;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
`;
// const StyledCondtionText = styled.div`
//   border-left: 2px solid #2e86fb;
//   padding-left: 24px;
//   font: normal normal 300 16px/23px Circe;
//   color: ${({ theme }) => theme.fg4};
//   text-align: center;
// `;
const StyledCondtionBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
`;

export default function PublicSaleCondtions({ title, footer, children }) {
  return (
    <StyledWrapper>
      <StyledCondtionTitle>
        <Info
          fill="#2e86fa"
          style={{ height: 23, width: "auto", marginRight: 16 }}
        />
        {title}
      </StyledCondtionTitle>
      <StyledCondtionBody>{children}</StyledCondtionBody>
      {footer && <StyledCondtionFooter>
        {footer}
      </StyledCondtionFooter>}
    </StyledWrapper>
  );
}
PublicSaleCondtions.propTypes = {
  title: Proptypes.string,
  footer: Proptypes.string,
  children: Proptypes.any,
};
