import { Row } from "antd";
import React, { useState, useEffect } from "react";
import Proptypes from "prop-types";

import { Link } from "react-router-dom";
import styled from "styled-components";
import { BigNumber, ethers } from "ethers";
import Page from "../lib/Page";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import StakingOfferCard from "../lib/StakingOfferCard";
import StakeModal from "../components/staking/StakeModal";
import {
  APP_LINKS,
  MAX_ALLOWANCE,
  STAKING_CONTRACT_ADDRESS,
  PUBLIC_SALE_END_DATE,
} from "../utils/constants";
import inconsistentColors from "../utils/helpers/inconsistentColors";
import { useMyTvStateContext } from "../components/providers/MyTvContextProvider";
import { useUserStateContext } from "../components/providers/UserContextProvider";
import useUpdateMyTvBalance from "../utils/hooks/useUpdateMyTvBalance";
import {
  makeGovernanceTokenContract,
  makeStakingContract,
} from "../utils/helpers/makeContracts";
import { useWeb3StateContext } from "../components/providers/Web3ContextProvier";

const StyledTitleSection = styled(Row)`
   width: 100%;
   margin-top: 47px;
   margin-bottom: 58px;
   border-bottom: 1px solid rgba(84, 91, 122, 0.65);
 `;
const StyledTitleSectionLeft = styled.div`
   text-align: left;
   font: normal normal bold 22px/32px Circe;
   letter-spacing: 0px;
   color: ${({ theme }) => theme.fg1};
 `;
const StyledTitleSectionRight = styled(Link)`
   text-align: right;
   text-decoration: underline;
   font: normal normal bold 18px/26px Circe;
   letter-spacing: 0px;
   color: ${inconsistentColors("#1F2952", "#F6F9FF")};
   &:hover {
     color: ${inconsistentColors("#1F2952", "#F6F9FF")};
   }

   &:hover {
     color: ${({ theme }) => theme.fg3};
   }
 `;
const MainContainer = styled.div`
   width: calc(100% - 32px);
   margin-left: 16px;
   margin-right: 16px;
 `;

const StyledStakingPage = styled(Page)`
  background-image: url("/images/grid1.png"), url("/images/dashboard-bg.png");
  background-repeat: no-repeat, no-repeat;
  background-attachment: fixed, fixed;
  background-position: right 70px top 10px, left 5px bottom;
`;

const StyledCardContainer = styled.div`
   display: grid;

   grid-template-columns: repeat(3, 361px);
   justify-content: center;
   align-items: center;
   justify-items: center;
   gap: 83px;

   padding-bottom: 70px;
`;

const StyledComingSoonCard = styled.div`
  margin-top: 40px;
  height: 545px;
  background-image: url(${({ $bg }) => $bg});
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  border-radius: 20px;
`;
const StlyedTxt = styled.div`
  position: absolute;
  text-align: left;
  font: normal normal bold 62px/91px Circe;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  top: 326px;
  left: 124px;
`;
const StyledColoredText = styled.div`
  position: absolute;
  top: 200px;
  left: 124px;
  text-align: left;
  font: normal normal bold 72px/106px Circe;
  color: #55c0f0;
  width: 455px;

  border-bottom: 1px solid #55c0f0;
`;
function ComingSoonCard({ text, backgroundImage }) {
  return (
    <StyledComingSoonCard $bg={backgroundImage}>
      <StyledColoredText>{text}</StyledColoredText>
      <StlyedTxt>
        Coming
        <div style={{ marginLeft: 150 }}>Soon</div>
      </StlyedTxt>
    </StyledComingSoonCard>
  );
}
ComingSoonCard.propTypes = {
  backgroundImage: Proptypes.string,
  text: Proptypes.string,
};

let intervalTimerId = 0;

function Staking() {
  const [modalsOpen, setModalOpen] = useState({});
  const { packs } = useMyTvStateContext();
  const { activeAccount } = useUserStateContext();
  const { web3 } = useWeb3StateContext();
  const updateMyTvBalance = useUpdateMyTvBalance();
  const [areLoading, setAreLoading] = useState({});

  const [canUseView, setCanUseView] = useState(PUBLIC_SALE_END_DATE <= new Date());
  useEffect(() => {
    window.clearInterval(intervalTimerId);
    if (!canUseView) {
      intervalTimerId = window.setInterval(() => {
        if (PUBLIC_SALE_END_DATE <= new Date()) {
          window.clearInterval(intervalTimerId);
          setCanUseView(true);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalTimerId);
    }
  });

  const comingSoonElement = (
    <ComingSoonCard
      backgroundImage="/images/dashboard-cs.png"
      text="STAKING"
    />
  );

  const viewElement = (
    <MainContainer>
      <StyledTitleSection justify="space-between" align="middle">
        <StyledTitleSectionLeft>Your Staking Offers</StyledTitleSectionLeft>
        <StyledTitleSectionRight to={`${APP_LINKS.STAKING_DETAILS}/staking`}>
          See my staking details
        </StyledTitleSectionRight>
      </StyledTitleSection>

      <StyledCardContainer>
        {(packs || []).map(({ apr, lock }, i) => {
          const packId = i + 1;
          const isOpen = modalsOpen[packId];
          const isLoading = areLoading[packId];
          const setIsOpen = (p) =>
            setModalOpen((o) => ({ ...o, [packId]: p }));
          const setIsLoading = (v) =>
            setAreLoading((o) => ({ ...o, [packId]: v }));
          const lockTxt = `Lock ${lock}d`;
          const title = `Pack ${packId}`;

          return (
            <React.Fragment key={i}>
              <StakingOfferCard
                bgImgUrl={`/images/img${packId}.png`}
                title={title}
                apr={`APR : ${apr}%`}
                lock={lockTxt}
                onBtnClick={() => setIsOpen(true)}
              />
              {isOpen && (
                <StakeModal
                  open
                  packId={packId}
                  title={title}
                  apr={apr}
                  lockPeriodDays={lock}
                  aprPeriod={lock ? `${lock}-Days APR` : `APR`}
                  depositFee="0 %"
                  withdrawFee="0 %"
                  loading={isLoading}
                  onCancel={() => {
                    setIsOpen(false);
                  }}
                  onSuccess={async ({ amount }) => {
                    if (!web3) return;
                    try {
                      const amountBlkChain = ethers.utils.parseUnits(
                        amount,
                        4
                      );

                      const stakingContract = makeStakingContract(web3);

                      const governanceContract =
                        makeGovernanceTokenContract(web3);
                      setIsLoading(true);

                      const myTvBal = await governanceContract.methods
                        .balanceOf(activeAccount)
                        .call();

                      if (BigNumber.from(myTvBal).lt(ethers.utils.parseUnits(amount, 4))) {
                        throw new Error("no balance");
                      }

                      const allowedMyTV = await governanceContract.methods
                        .allowance(activeAccount, STAKING_CONTRACT_ADDRESS)
                        .call();

                      if (amountBlkChain.gt(allowedMyTV)) {
                        await governanceContract.methods
                          .approve(STAKING_CONTRACT_ADDRESS, MAX_ALLOWANCE)
                          .send({ from: activeAccount });
                      }

                      const res = await stakingContract.methods
                        .stake(packId, amountBlkChain)
                        .send({ from: activeAccount });

                      setIsOpen(false);
                      console.log(res);
                    } catch (e) {
                      console.error(e);
                    } finally {
                      await updateMyTvBalance();
                      setIsLoading(false);
                    }
                  }}
                />
              )}
            </React.Fragment>
          );
        })}
      </StyledCardContainer>
    </MainContainer>
  );
  return (
    <StyledStakingPage header={<DashboardHeader />} requireLoggedIn={false}>
      {canUseView ? viewElement : comingSoonElement}
    </StyledStakingPage>
  );
}

export default Staking;
