import React, { useContext, useMemo, useReducer } from "react";
import Proptypes from "prop-types";
import { ThemeProvider } from "styled-components";
import { LIGHT_THEME } from "../../utils/themes";
import getThemeInitialState from "../../utils/helpers/getThemeInitialState";

const initialState = LIGHT_THEME;

export const ThemeStateContext = React.createContext(initialState);
export const ThemeDispatchContext = React.createContext(() => {});

export const useThemeStateContext = () => useContext(ThemeStateContext);
export const useThemeDispatchContext = () => useContext(ThemeDispatchContext);

function reducer(state, action) {
  switch (action.type) {
    case "set-theme":
      return action.payload;
    case "reset":
      return initialState;
    default:
      throw new Error("Bad Action Type");
  }
}

function StyledComponentThemeProvider({ children }) {
  const activeTheme = useContext(ThemeStateContext);
  return <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>;
}
StyledComponentThemeProvider.propTypes = { children: Proptypes.any };

function ThemeContextProvier({ children }) {
  const initialTheme = useMemo(() => getThemeInitialState(), []);
  const [state, dispatch] = useReducer(reducer, initialTheme);
  return (
    <ThemeDispatchContext.Provider value={dispatch}>
      <ThemeStateContext.Provider value={state}>
        <StyledComponentThemeProvider>{children}</StyledComponentThemeProvider>
      </ThemeStateContext.Provider>
    </ThemeDispatchContext.Provider>
  );
}
ThemeContextProvier.propTypes = { children: Proptypes.any };

export default ThemeContextProvier;
