import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { Col, Row } from "antd";
import Page from "../lib/Page";
import NestedPagesHeader from "../lib/NestedPagesHeader";
import dashboard from "../icons/dashboard.svg";
import Accordion from "../lib/Accordion";
import { APP_LINKS, THEMES } from "../utils/constants";

const StyeldFaqTop = styled(Row)`
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  margin-top: 40px;
  border-radius: 15px;
  height: 128px;
  overflow: hidden;
`;

const StyledFaqTitle = styled.div`
  text-align: left;
  font: normal normal 300 32px/48px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg3};
  padding: 40px;
`;

const StyledFaqCont = styled.div`
  margin-top: 32px;
`;

function Faq() {
  const theme = useContext(ThemeContext);

  return (
    <Page
      header={
        <NestedPagesHeader
          title="Your dashboard"
          icon={dashboard}
          link={APP_LINKS.DASHBOARD}
        />
      }
    >
      <StyeldFaqTop justify="space-between">
        <Col>
          <StyledFaqTitle>FAQ</StyledFaqTitle>
        </Col>
        <Col style={{ height: "100%" }}>
          <img
            src={
              theme.id === THEMES.LIGHT
                ? "/images/faq.png"
                : "/images/faq-dark.png"
            }
            alt=""
            style={{
              height: "100%",

              widht: "auto",
            }}
          />
        </Col>
      </StyeldFaqTop>

      <StyledFaqCont>
        <Accordion
          initialActive
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien lectus. Proin vitae ligula metus ?"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien
          lectus. Proin vitae ligula metus. Nullam tincidunt efficitur sapien.
          Aenean nibh nunc, bibendum et gravida eu, posuere et risus.
          Pellentesque semper neque ac augue tempor, eu dapibus lectus gravida.
          Ut vitae ex mi. Morbi eget pulvinar metus. Sed fringilla turpis id ex
          commodo, id interdum risus mollis. Maecenas sit amet felis nisl. Donec
          ac massa sed elit varius elementum. Proin porta dolor ex, sit amet
          volutpat justo aliquam a.
        </Accordion>

        <Accordion
          style={{ marginTop: 32 }}
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien lectus. Proin vitae ligula metus ?"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien
          lectus. Proin vitae ligula metus. Nullam tincidunt efficitur sapien.
          Aenean nibh nunc, bibendum et gravida eu, posuere et risus.
          Pellentesque semper neque ac augue tempor, eu dapibus lectus gravida.
          Ut vitae ex mi. Morbi eget pulvinar metus. Sed fringilla turpis id ex
          commodo, id interdum risus mollis. Maecenas sit amet felis nisl. Donec
          ac massa sed elit varius elementum. Proin porta dolor ex, sit amet
          volutpat justo aliquam a.
        </Accordion>

        <Accordion
          style={{ marginTop: 32 }}
          title="Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien lectus. Proin vitae ligula metus ?"
        >
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In id sapien
          lectus. Proin vitae ligula metus. Nullam tincidunt efficitur sapien.
          Aenean nibh nunc, bibendum et gravida eu, posuere et risus.
          Pellentesque semper neque ac augue tempor, eu dapibus lectus gravida.
          Ut vitae ex mi. Morbi eget pulvinar metus. Sed fringilla turpis id ex
          commodo, id interdum risus mollis. Maecenas sit amet felis nisl. Donec
          ac massa sed elit varius elementum. Proin porta dolor ex, sit amet
          volutpat justo aliquam a.
        </Accordion>
      </StyledFaqCont>
    </Page>
  );
}

export default Faq;
