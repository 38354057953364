import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";

const StyledStakingDataItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 20px;
  height: 85px;
  max-width: 100%;
`;
const StyledStakingDataValue = styled.div`
  font: normal normal bold 16px/23px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  width: 50%;
  height: 100%;
  padding-right: 30px;
  border-right: 1px solid ${({ theme }) => theme.border3};
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const StyledStakingDataText = styled.div`
  font: normal normal 300 18px/26px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  opacity: 1;
  text-align: left;
  padding-left: 30px;
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
export default function StakingDataItem({ value, text }) {
  return (
    <StyledStakingDataItem>
      <StyledStakingDataValue>{value}</StyledStakingDataValue>
      <StyledStakingDataText>{text}</StyledStakingDataText>
    </StyledStakingDataItem>
  );
}
StakingDataItem.propTypes = {
  value: PropTypes.string,
  text: PropTypes.string,
};
