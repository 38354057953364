export const LIGHT_THEME = {
  id: "light",
  bg1: "#EAF0F8", // background
  bg2: "#FFFFFF",
  bg3: "#F7FAFF",
  bg4: "#F7F7F7",
  bg5: "#F6F9FF",
  bg6: "#fafbfd",

  fg1: "#121A3D", // foreground
  fg2: "#2D70D0",
  fg3: "#1f2952",
  fg4: "#121b3e",

  icon1: "rgba(18, 26, 61,0.5)",
  icon2: "#121A3D",
  icon3: "#339DFF",

  border1: "#EBEBEB",
  border2: "#6b738e",
  border3: "#08A0F7",

  sfcBg1: "#ffffff",
  sfcBg2: "#f0f5fc",

  sfcTvlWrapper: "#eaf0f8",
  titleModal: "#2D70D0",
  textContentModal: "#141D40",

  // currency dropDown
  dd1: "#121A3D",
  ddBorder: "#2D70D0",
  ddBg: "white",
  ddHoverBg: "#EAF0F8",
};

export const DARK_THEME = {
  id: "dark",
  bg1: "#121A3D",
  bg2: "#253261",
  bg3: "#21294f",
  bg4: "#21294f",
  bg5: "#162043",
  bg6: "#626c8f",

  fg1: "#F6F9FF",
  fg2: "#c1ddfc",
  fg3: "#BADEFF",
  fg4: "#FFFFFF",

  icon1: "#c1ddfc",
  icon2: "#7487ad",
  icon3: "#BADEFF",

  border1: "#465484",
  border2: "#545B7A",
  border3: "#545B7A",

  // Stacking Farming card
  sfcBg1: "#222F5D",
  sfcBg2: "#536195",

  sfcTvlWrapper: "#1F2952",
  titleModal: "#BADEFF",
  textContentModal: "#F6F9FF",

  // currency dropDown
  dd1: "white",
  ddBorder: "#2D70D0",
  ddBg: "#162043",
  ddHoverBg: "#253261",
};
