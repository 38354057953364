import { Col, Row } from "antd";
import Proptypes from "prop-types";
import React, { useContext, useState } from "react";
import styled, { ThemeContext } from "styled-components";
import Page from "../lib/Page";
import DashboardHeader from "../components/dashboard/DashboardHeader";
import { ReactComponent as SwapIcon } from "../icons/swap.svg";
import plusIcon from "../icons/plus.svg";
import arrowDown from "../icons/arrowDown.svg";
import mytvRounedLogo from "../icons/roundedLogo.png";

import MyTvButton from "../lib/MyTvButton";
import ExchangeBlock from "../lib/ExchangeBlock";
import inconsistentColors from "../utils/helpers/inconsistentColors";
//import { PUBLIC_SALE_END_DATE } from "../utils/constants";

const StyledMainContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 170px 170px 170px;
  gap: 23px;
  margin-top: 38px;

  /* padding: 0 166px; */
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
`;

const StyledSelectBtn = styled.div`
  background: ${({ theme, $active }) =>
    $active ? theme.bg2 : inconsistentColors("#F6F9FF", "#1F2952")}
    0% 0% no-repeat padding-box;
  color: ${({ theme, $active }) =>
    $active
      ? theme.fg1
      : inconsistentColors(
        "rgba(18, 26, 61, 0.3)",
        "rgba(246, 249, 255, 0.3)"
      )};

  border-radius: 15px;
  width: 321px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;

  font: normal normal bold 32px/48px Circe;
  letter-spacing: 0px;
  /* opacity: ${({ active }) => (active ? 1 : 0.3)}; */
  cursor: pointer;
  user-select: none;
  justify-self: flex-end;
`;

const StyledMainCard = styled.div`
  height: 100%;
  grid-column: 2;
  grid-row: 1 / span 3;
  background: ${({ theme }) => theme.bg2} 0% 0% no-repeat padding-box;
  border-radius: 15px;
  align-self: start;
  justify-self: stretch;
  width: 650px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

const StyledSwapInputWrapper = styled(Row)`
  position: relative;
  background-color: ${({ theme }) => theme.bg2};
  border-radius: 10px;
  height: 55px;
  padding: 11px 8px;
  padding-right: 33px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledExchangeName = styled.div`
  font: normal normal bold 24px/35px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledExchangeRateLine = styled(Row)`
  font: normal normal 300 16px/23px Circe;
  letter-spacing: 0px;
  color: #1f2952;
  width: 100%;
  margin-top: 24px;
`;
const StyledNotch = styled.div`
  border-left: 3px solid #71c0eb;
  padding: 0 10px;
  color: ${({ theme }) => theme.fg1};
`;

const StyledSeparatingIcon = styled.img`
  height: 30px;
  width: auto;
  object-fit: cover;
  width: 30px;
  align-self: center;
`;
const ColText = styled(Col)`
  color: ${({ theme }) => theme.fg1};
`;
const LiquidityValue = styled.div`
  color: ${({ theme }) => theme.fg1};
`;

const StyledMyTvButton = styled(MyTvButton)`
  width: 350px;
  margin-top: auto;
  margin-bottom: 0;
  align-self: center;
`;

const StyledClickable = styled.div`
  cursor: pointer;
  user-select: none;
  /* max-width: min-content;
  height: max-content; */
  align-self: center;
`;

const options = [
  { id: "MYTV", label: "MYTV", url: "/images/extras/roundedLogo.png" },
  { id: "BNB", label: "BNB", url: "/images/extras/bnbLogo.png" },
  { id: "BUSD", label: "BUSD", url: "/images/extras/busd.svg" },
  { id: "WBTC", label: "WBTC", url: "/images/extras/bitcoin.png" },
];

const initVal = { input: "", currency: options[0] };
//let intervalTimerId = 0;

function SwapCard() {
  const theme = useContext(ThemeContext);
  const [exchB1, setExchB1] = useState(initVal);
  const [exchB2, setExchB2] = useState(initVal);
  return (
    <StyledMainCard>
      <ExchangeBlock
        note="Input"
        value={exchB1}
        onChange={setExchB1}
        options={options}
      />

      <StyledClickable
        style={{ margin: "24px 0" }}
        onClick={() => {
          const temp = exchB1;
          setExchB1(exchB2);
          setExchB2(temp);
        }}
      >
        <SwapIcon fill={inconsistentColors("#339dff", "#506193")({ theme })} />
      </StyledClickable>

      <ExchangeBlock
        note="Output"
        value={exchB2}
        onChange={setExchB2}
        options={options}
      />
      <StyledExchangeRateLine justify="space-between" align="top">
        <Col>
          <StyledNotch>Exchange rate</StyledNotch>
        </Col>
        <ColText>
          1 {exchB1.currency.label} = 0.002 {exchB2.currency.label}
        </ColText>
      </StyledExchangeRateLine>
      <StyledMyTvButton>SWAP</StyledMyTvButton>
    </StyledMainCard>
  );
}

function AddLiquidityCard() {
  const [exchB1, setExchB1] = useState(initVal);
  const [exchB2, setExchB2] = useState(initVal);
  return (
    <StyledMainCard>
      <ExchangeBlock
        note="Input"
        value={exchB1}
        onChange={setExchB1}
        options={options}
      />
      <StyledSeparatingIcon
        src={plusIcon}
        style={{ margin: "24px 0 19px 0" }}
      />

      <ExchangeBlock
        note="Input"
        value={exchB2}
        onChange={setExchB2}
        options={options}
      />
      <StyledExchangeRateLine justify="space-between" align="top">
        <Col>
          <StyledNotch>Exchange rate</StyledNotch>
        </Col>
        <Col style={{ textAlign: "right" }}>
          <LiquidityValue>1 MYTV = 0.002 BNB</LiquidityValue>
          <LiquidityValue>0.00% Share of pool</LiquidityValue>
        </Col>
      </StyledExchangeRateLine>
      <StyledMyTvButton>ADD LIQUIDITY</StyledMyTvButton>
    </StyledMainCard>
  );
}

const StyledRemoveLiquidityTitle = styled.div`
  text-align: left;
  font: normal normal bold 24px/35px Circe;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.fg1};
  margin-bottom: 16px;
`;

const StyledExchangeBlock2 = styled.div`
  background: ${({ theme }) => theme.bg5} 0% 0% no-repeat padding-box;
  border-radius: 10px;
  padding: 22px 32px;
`;

function RemoveLiquidityMainCard() {
  const [exchB, setExchB] = useState(initVal);
  return (
    <StyledMainCard>
      <StyledRemoveLiquidityTitle>
        Amount to withdraw
      </StyledRemoveLiquidityTitle>
      <ExchangeBlock
        note="LP"
        value={exchB}
        onChange={setExchB}
        options={options}
      />
      <StyledSeparatingIcon src={arrowDown} style={{ margin: "24px 0" }} />
      <StyledExchangeBlock2>
        <Row
          justify="start"
          align="start"
          gutter={[20, 20]}
          style={{ width: "100%" }}
        >
          <Col>
            <StyledExchangeName>You will receive</StyledExchangeName>
          </Col>
        </Row>
        <br />
        <Row gutter={[15, 0]}>
          <Col span={12}>
            <StyledSwapInputWrapper align="middle">
              <img
                src={mytvRounedLogo}
                alt=""
                style={{ width: 33, height: 33, objectFit: "cover" }}
              />
              <div style={{ marginLeft: 16 }}>MYTV</div>

              <div style={{ marginLeft: "auto", marginRight: 0 }}>0.0</div>
            </StyledSwapInputWrapper>
          </Col>
          <Col span={12}>
            <StyledSwapInputWrapper align="middle">
              <img
                src="/images/extras/bnbLogo.png"
                alt=""
                style={{ width: 33, height: 33, objectFit: "cover" }}
              />
              <div style={{ marginLeft: 16 }}>MYTV</div>

              <div style={{ marginLeft: "auto", marginRight: 0 }}>0.0</div>
            </StyledSwapInputWrapper>
          </Col>
        </Row>
      </StyledExchangeBlock2>

      <StyledMyTvButton>USE</StyledMyTvButton>
    </StyledMainCard>
  );
}

const StyledPage = styled(Page)`
  background-image: url("/images/dashboard-bg.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: left bottom;
`;

const StyledComingSoonCard = styled.div`
  margin-top: 40px;
  height: 545px;
  background-image: url(${({ $bg }) => $bg});
  color: white;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  position: relative;
  border-radius: 20px;
`;
const StlyedTxt = styled.div`
  position: absolute;
  text-align: left;
  font: normal normal bold 62px/91px Circe;
  letter-spacing: 0px;
  color: #ffffff;
  text-transform: uppercase;
  top: 326px;
  left: 124px;
`;
const StyledColoredText = styled.div`
  position: absolute;
  top: 200px;
  left: 124px;
  text-align: left;
  font: normal normal bold 72px/106px Circe;
  color: #55c0f0;
  width: 455px;

  border-bottom: 1px solid #55c0f0;
`;

function ComingSoonCard({ text, backgroundImage }) {
  return (
    <StyledComingSoonCard $bg={backgroundImage}>
      <StyledColoredText>{text}</StyledColoredText>
      <StlyedTxt>
        Coming
        <div style={{ marginLeft: 150 }}>Soon</div>
      </StlyedTxt>
    </StyledComingSoonCard>
  );
}
ComingSoonCard.propTypes = {
  backgroundImage: Proptypes.string,
  text: Proptypes.string,
};
function SwapBody() {
  const [active, setActive] = useState(1);

  const [canUseView] = useState(false);
  //const [canUseView, setCanUseView] = useState(PUBLIC_SALE_END_DATE <= new Date());
  /*useEffect(() => {
    window.clearInterval(intervalTimerId);
    if (!canUseView) {
      intervalTimerId = window.setInterval(() => {
        if (PUBLIC_SALE_END_DATE <= new Date()) {
          window.clearInterval(intervalTimerId);
          setCanUseView(true);
        }
      }, 500);
    }

    return () => {
      window.clearInterval(intervalTimerId);
    }
  });*/

  const comingSoonElement = (
    <ComingSoonCard backgroundImage="/images/swap-cs.png" text="SWAP" />
  );

  const viewElement = (
    <StyledMainContainer>
      <StyledSelectBtn $active={active === 1} onClick={() => setActive(1)}>
        Swap
      </StyledSelectBtn>
      <StyledSelectBtn $active={active === 2} onClick={() => setActive(2)}>
        Add liquidity
      </StyledSelectBtn>
      <StyledSelectBtn $active={active === 3} onClick={() => setActive(3)}>
        Remove liquidity
      </StyledSelectBtn>
      {active === 1 ? <SwapCard /> : null}
      {active === 2 ? <AddLiquidityCard /> : null}
      {active === 3 ? <RemoveLiquidityMainCard /> : null}

      {/* {active === 1 ? (
        <ComingSoonCard backgroundImage="/images/swap1.png" />
      ) : null}
      {active === 2 ? (
        <ComingSoonCard backgroundImage="/images/swap2.png" />
      ) : null}
      {active === 3 ? (
        <ComingSoonCard backgroundImage="/images/swap3.png" />
      ) : null} */}
    </StyledMainContainer>
  );
  return (
    <StyledPage header={<DashboardHeader />} requireLoggedIn={false}>
      {canUseView ? viewElement : comingSoonElement}
    </StyledPage>
  );
}

export default SwapBody;
