import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeContextProvier from "./components/providers/ThemeContextProvider";
import UserContextProvier from "./components/providers/UserContextProvider";
import PublicSaleContextProvier from "./components/providers/PublicSaleContextProvider";
import UIContextProvier from "./components/providers/UIContextProvider";
import MyTvContextProvier from "./components/providers/MyTvContextProvider";
import Web3ContextProvier from "./components/providers/Web3ContextProvier";

ReactDOM.render(
  <React.StrictMode>
    <UserContextProvier>
      <ThemeContextProvier>
        <PublicSaleContextProvier>
          <UIContextProvier>
            <MyTvContextProvier>
              <Web3ContextProvier>
                <App />
              </Web3ContextProvier>
            </MyTvContextProvier>
          </UIContextProvier>
        </PublicSaleContextProvier>
      </ThemeContextProvier>
    </UserContextProvier>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
