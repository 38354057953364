import React from "react";
import Proptypes from "prop-types";
import styled from "styled-components";
// import { useUserStateContext } from "../components/providers/UserContextProvider";
// import NotConnectedPage from "./NotConnectedPage";

const StyledPageContainer = styled.div`
  width: fit-content;
  min-width: 100%;
  height: 100%;
  min-height: 100vh;

  padding: 32px;
  padding-bottom: 0;

  background-color: ${({ theme }) => theme.bg1};
`;
const StyledElmsContainer = styled.div`
  min-width: 1024px;
  max-width: 1302px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

const StyledPageContent = styled.div`
  /* max-width: 1260px;   TODO: DO NOT REMOVE */
  margin: 0 auto;
  width: 100%;
  height: 100%;
`;

function Page({
  children,
  header = null,
  className,
  style,
  // requireLoggedIn = true,
}) {
  // const { activeAccount } = useUserStateContext();
  return (
    <StyledPageContainer className={className} style={style}>
      <StyledElmsContainer>
        {header}
        <StyledPageContent>
          {/* {activeAccount || !requireLoggedIn ? children : <NotConnectedPage />} */}
          {children}
        </StyledPageContent>
      </StyledElmsContainer>
    </StyledPageContainer>
  );
}

Page.propTypes = {
  children: Proptypes.any,
  className: Proptypes.string,
  style: Proptypes.any,
  header: Proptypes.any,
  // requireLoggedIn: Proptypes.bool,
};

export default Page;
