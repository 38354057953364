import React, { useContext, useReducer } from "react";
import Proptypes from "prop-types";

const initialState = {
  activeAccount: null,
  activeNetwork: null,
  activeChainId: null,
  providerName: null,
  provider: null,
  web3: null,
  // mytvBalance: 0,
  // formatedMytvBalance: "0.0",
  balance: null,
  balanceTokens: null,
  totalRewards: null,
  totalTokensRewards: null,
  totalTvl: null,
  balanceBNB: null,
};

export const UserStateContext = React.createContext(initialState);
export const UserDispatchContext = React.createContext(() => { });

export const useUserStateContext = () => useContext(UserStateContext);
export const useUserDispatchContext = () => useContext(UserDispatchContext);

const ACTION_UPDATE_ACTIVE_ACCOUNT = "set-active-account";
const ACTION_UPDATE_ACTIVE_NETWORK = "set-active-network";
const ACTION_UPDATE_ACTIVE_CHAIN_ID = "set-active-chain-id";

const ACTION_RESET = "reset";
const ACTION_SET_BNB_BALANCE = "set-bnb-balance";
const ACTION_SET_KPIS = "user-set-kpis";

export const updateActiveAccount = (payload) => ({
  type: ACTION_UPDATE_ACTIVE_ACCOUNT,
  payload,
});

export const updateActiveNetwork = (payload) => ({
  type: ACTION_UPDATE_ACTIVE_NETWORK,
  payload,
});

export const updateActiveChainId = (payload) => ({
  type: ACTION_UPDATE_ACTIVE_CHAIN_ID,
  payload,
});

export const updateBNBBalance = (payload) => ({
  type: ACTION_SET_BNB_BALANCE,
  payload,
});

export const userSetKpis = ({
  balance,
  balanceTokens,
  totalRewards,
  totalTokensRewards,
  totalTvl,
}) => ({
  type: ACTION_SET_KPIS,
  payload: {
    balance,
    balanceTokens,
    totalRewards,
    totalTokensRewards,
    totalTvl,
  },
});

export const resetUserContext = () => ({ type: ACTION_RESET });

function reducer(state, action) {
  switch (action.type) {
    case ACTION_UPDATE_ACTIVE_ACCOUNT:
      return { ...state, activeAccount: action.payload };
    case ACTION_UPDATE_ACTIVE_NETWORK:
      return { ...state, activeNetwork: action.payload };
    case ACTION_UPDATE_ACTIVE_CHAIN_ID:
      return { ...state, activeChainId: action.payload };
    case ACTION_SET_KPIS:
      return { ...state, ...action.payload };
    case ACTION_SET_BNB_BALANCE:
      return { ...state, balanceBNB: action.payload };
    case ACTION_RESET:
      return initialState;
    default:
      console.log(action);
      throw new Error("Bad Action Type");
  }
}

function UserContextProvier({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <UserDispatchContext.Provider value={dispatch}>
      <UserStateContext.Provider value={state}>
        {children}
      </UserStateContext.Provider>
    </UserDispatchContext.Provider>
  );
}
UserContextProvier.propTypes = { children: Proptypes.any };

export default UserContextProvier;
