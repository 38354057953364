export default function commaSeparatedNumber(num, decimal = 2) {
  if (Number.isNaN(num)) return "0";

  let rest = "";
  let val = (~~num).toString();

  if (decimal !== 0) {
    rest = (parseFloat(num) - ~~num).toFixed(decimal).replace("0.", "");
  };

  val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  if (rest === "") {
    return val;
  }

  return `${val}.${rest}`;
}
